import { formatMontant } from '@jvs-group/jvs-mairistem-finances-utils';
import BaseControleCoherence from './BaseControleCoherence';
import FamilleControleCoherence from '../../../constants/simulation/familleControleCoherence';
import TypeBudget from '../../../constants/simulation/typeBudget';
import StatutControleCoherence from '../../../constants/simulation/statutControleCoherence';
import type EquilibreOperationOrdreTransfertInvestissementData from '../../../interfaces/outil/controleCoherence/equilibreOperationOrdreTransfertInvestissementData';

class EquilibreOperationOrdreTransfertInvestissement extends BaseControleCoherence {
  data: EquilibreOperationOrdreTransfertInvestissementData;

  famille = FamilleControleCoherence.OPERATIONS_ORDRE;

  constructor(data: EquilibreOperationOrdreTransfertInvestissementData) {
    super(data);
    this.data = data;
  }

  getExplicatif(typeBudget: TypeBudget): string {
    const depenseInvestissement040 = formatMontant(this.data.depenseInvestissement040, 2, true);
    const recetteFonctionnement042 = formatMontant(this.data.recetteFonctionnement042, 2, true);

    switch (this.statut) {
      case StatutControleCoherence.ERROR:
        if (typeBudget === TypeBudget.COMPTE_ADMINISTRATIF) {
          return `Le total des dépenses d'investissement réalisées au chapitre 040 (${depenseInvestissement040}) diffère des recettes de fonctionnement réalisées au 042 (${recetteFonctionnement042}).`;
        }
        return `Le total des prévisions budgétaires inscrites en dépenses d'investissement au chapitre 040 (${depenseInvestissement040}) diffère des recettes de fonctionnement inscrites au 042 (${recetteFonctionnement042}).`;
      case StatutControleCoherence.OK:
        if (typeBudget === TypeBudget.COMPTE_ADMINISTRATIF) {
          return `Le total des dépenses d'investissement réalisées au chapitre 040 est égal aux recettes de fonctionnement réalisées au 042 (${depenseInvestissement040}).`;
        }
        return `Le total des prévisions budgétaires inscrites en dépenses d'investissement au chapitre 040 est égal aux recettes de fonctionnement inscrites au 042 (${depenseInvestissement040}).`;
      default:
        return '';
    }
  }

  getLibelle(): string {
    switch (this.statut) {
      case StatutControleCoherence.ERROR:
        return 'Déséquilibre des opérations d’ordre de transfert de la section d\'investissement vers la section de fonctionnement';
      case StatutControleCoherence.OK:
        return 'Équilibre des opérations d’ordre de transfert de la section d\'investissement vers la section de fonctionnement';
      default:
        return '';
    }
  }

  getStatut(data: EquilibreOperationOrdreTransfertInvestissementData): StatutControleCoherence {
    const { depenseInvestissement040, recetteFonctionnement042 } = data;

    if (depenseInvestissement040 === recetteFonctionnement042) {
      return StatutControleCoherence.OK;
    }

    return StatutControleCoherence.ERROR;
  }
}

export default EquilibreOperationOrdreTransfertInvestissement;
