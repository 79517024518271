import { isEmpty } from 'lodash';

import { request } from '@jvs-group/jvs-mairistem-store';
import type { RARDropdownValues } from '../../components/simulation/Outils/CalculRARModal';
import type Simulation from '../../interfaces/simulation/simulation';
import type DetailCalcul from '../../interfaces/simulation/detailCalcul';
import type BaseTypeRegroupement from '../../classes/typeRegroupement/BaseTypeRegroupement';
import type ExportExcelValues from '../../interfaces/simulation/exportExcelValues';
import type Reports from '../../interfaces/simulation/reports';
import type ImputationEmprunt from '../../interfaces/simulation/ImputationEmprunt';
import type ParametresSimulation from '../../interfaces/simulation/parametresSimulation';

const url = '/api/finances/simulation/';

export const annulationTransfertComptabilite = (identifiant: number) => request.get<any, any>(
  `${url}${identifiant}/annulationTransfertComptabilite`,
);

export const annulationVote = (identifiant: number) => request.get<any, any>(`${url}${identifiant}/annulationVote`);

export const calculImputations = (
  identifiantSimulation: number,
  filters: any,
  data: {
    identifiantRegleCalcul: number
    pourcentageMajoration: number,
    montantForfaitaire: number,
    typeArrondi: number,
    codeFeuille: string,
  },
) => request.post(`${url}${identifiantSimulation}/calculImputations`, { ...data, ...filters });

export const calculResteRealise = (identifiant: number, values: RARDropdownValues) => {
  const params = new URLSearchParams();

  Object.entries(values).forEach(([key, value]) => {
    params.append(key, value.toString());
  });

  return request.get(`${url}${identifiant}/calculResteRealise?${params.toString()}`);
};

export const calculVirementSection = (simulation: Simulation) => request.get<unknown, unknown>(
  `${url}${simulation.identifiant}/calculVirementSection?typePeriode=${simulation.typePeriode}`,
);

export const getControleCoherence = ({ identifiant, typePeriode }: Simulation) => request.get<any, any>(
  `${url}${identifiant}/controleCoherencePrevisions?typePeriode=${typePeriode}`,
);

export const getDetailCalculImputations = (
  identifiantSimulation: number,
  filters: any,
  typesRegroupement: BaseTypeRegroupement[],
  data: {
    identifiantRegleCalcul: number
    pourcentageMajoration: number,
    montantForfaitaire: number,
    typeArrondi: number,
    codeFeuille: string,
  },
) : Promise<DetailCalcul> => {
  const params = new URLSearchParams();
  params.append('identifiantRegleCalcul', data.identifiantRegleCalcul?.toString());
  params.append('pourcentageMajoration', data.pourcentageMajoration?.toString());
  params.append('montantForfaitaire', data.montantForfaitaire?.toString());
  params.append('typeArrondi', data.typeArrondi?.toString());
  params.append('codeFeuille', data.codeFeuille);
  if (!isEmpty(filters)) {
    Object.entries(filters).forEach(([key, value]) => {
      params.append(key, value.toString());
    });
  }

  typesRegroupement.forEach((typeRegroupement) => {
    params.append('typesRegroupement[]', typeRegroupement.code);
  });

  return request.get(`${url}${identifiantSimulation}/detailCalculImputations?${params.toString()}`);
};

export const getExportExcel = (identifiant: number, values: ExportExcelValues) => {
  const params = new URLSearchParams();

  Object.entries(values).forEach(([key, value]) => {
    params.append(key, value.toString());
  });

  return request.download<any, any>(`${url}${identifiant}/exportExcel?${params.toString()}`);
};

export const getMontantSimulationNotSamePropVoteCount = (identifiant: number) => request.get<any, number>(
  `${url}${identifiant}/getMontantSimulationNotSamePropVoteCount`,
);

export const getReports = (identifiant: number) => request.get<any, Reports>(`${url}${identifiant}/getReports`);

export const getRepriseRestesARealiser = (identifiant: number) => request.get<any, any>(
  `${url}${identifiant}/getRepriseRestesARealiser`,
);

export const getVirementsSection = (simulation: Simulation) => request.get<any, any>(
  `${url}${simulation.identifiant}/getVirementsSection?typePeriode=${simulation.typePeriode}`,
);

export const getSimulation = (identifiant: number) => {
  const params = new URLSearchParams();

  params.append('withBudget', 'true');
  params.append('withDM', 'true');
  params.append('withEntite', 'true');
  params.append('withImputationsSimulation', 'true');
  params.append('withTypePlan', 'true');

  return request.get<unknown, Simulation>(`${url}${identifiant}?${params.toString()}`);
};

export const importBiens = (identifiant: number) => request.get<unknown, ImputationEmprunt[]>(
  `${url}${identifiant}/importBiens`,
);

export const importEmprunt = (identifiant: number) => request.get<unknown, ImputationEmprunt[]>(
  `${url}${identifiant}/importEmprunt`,
);

export const propToVote = (identifiant: number, shouldResetMontant: boolean) => request.get<any, any>(
  `${url}${identifiant}/propToVote?shouldResetMontant=${shouldResetMontant}`,
);

export const transfertComptabilite = (identifiant: number) => request.get<any, any>(
  `${url}${identifiant}/transfertComptabilite`,
);

export const updateSimulation = (
  identifiantSimulation: number,
  data: ParametresSimulation,
) => request.put<ParametresSimulation, Simulation>(`${url}${identifiantSimulation}`, data);

export const recopieReport = (simulation: Simulation, values?: object) => {
  const params = new URLSearchParams();

  if (values) {
    Object.keys(values).forEach((key) => {
      const value = values[key];
      if (!isEmpty(value)) params.append(key, value);
    });
  }

  params.append('typePeriode', String(simulation?.typePeriode));

  return request.get<unknown, unknown>(`${url}${simulation?.identifiant}/recopieReport?${params.toString()}`);
};

export const repriseRestesARealiser = (identifiant: number) => request.get<any, any>(
  `${url}${identifiant}/repriseRestesARealiser`,
);

export const sendImportExcel = (
  identifiant: number,
  file: FormData,
  substitute: boolean,
) => request.upload(`${url}${identifiant}/importExcel?substitute=${substitute}`, file);
