import React from 'react';
import { toast } from 'react-toastify';
import { Button, Form } from '@jvs-group/jvs-mairistem-composants';
import { getErrorMessage } from '@jvs-group/jvs-mairistem-finances-utils';
import type OutilModalProps from '../../../../interfaces/simulation/outilModalProps';
import type ExportExcelValues from '../../../../interfaces/simulation/exportExcelValues';
import { getExportExcel } from '../../../../services/simulation/simulation';

const ExportExcel = ({ simulation }: Pick<OutilModalProps, 'simulation'>) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [values, setValues] = React.useState<ExportExcelValues>({
    wantYearMinusOne: false,
    wantYearMinusTwo: false,
  });

  const handleChange = (e, { checked, name }: { checked: boolean, name: string }) => {
    setValues((old) => ({
      ...old,
      [name]: checked,
    }));
  };

  const handleExport = async () => {
    try {
      setLoading(true);
      await getExportExcel(simulation.identifiant, values);
    } catch (e) {
      toast.error(getErrorMessage(e, "Erreur lors de l'export"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Form>
        {`Pour les exercices antérieurs, seules les lignes budgétaires
          ayant une correspondance avec les lignes de l’exercice courant sont comptabilisées.`}
        <Form.Group grouped>
          <Form.Checkbox
            checked={values.wantYearMinusTwo}
            label={`Données ${simulation.exercice.anneeExercice - 2}`}
            name="wantYearMinusTwo"
            onChange={handleChange}
            toggle
          />
          <Form.Checkbox
            checked={values.wantYearMinusOne}
            label={`Données ${simulation.exercice.anneeExercice - 1}`}
            name="wantYearMinusOne"
            onChange={handleChange}
            toggle
          />
          <Form.Checkbox
            checked
            disabled
            label={`Données ${simulation.exercice.anneeExercice}`}
            toggle
          />
        </Form.Group>
      </Form>
      <div>
        <Button
          content="Récupérer le fichier"
          data-testid="exportButton"
          disabled={loading}
          floated="right"
          loading={loading}
          onClick={handleExport}
          positive
        />
      </div>
    </>
  );
};

export default ExportExcel;
