import { isNil } from 'lodash';

export interface CreateImputation {
  codeChapitre: string;
  codeArticle: string;
  codeFonction: string;
  codeOperation: string;
  codeVentilation: string;
  codeAnalytique: string;
  sens: string;
  section: string;
  cha_libelle?: string;
  art_libelle?: string;
  fon_libelle?: string;
  ope_libelle?: string;
  ven_libelle?: string;
  ana_libelle?: string;
}

export const checkImputation = (imputation: CreateImputation) => {
  if (isNil(imputation?.sens)) {
    throw new Error('Le sens est obligatoire');
  }

  if (isNil(imputation?.section)) {
    throw new Error('La section est obligatoire');
  }

  if (isNil(imputation?.codeChapitre)) {
    throw new Error('La chapitre est obligatoire');
  }

  if (isNil(imputation?.codeArticle)) {
    throw new Error("L'article est obligatoire");
  }
};
