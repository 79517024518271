import TypeRegroupement from '../../constants/feuilleSaisie/typeRegroupement';
import type TreeRow from '../../interfaces/feuilleSaisie/treeRow';
import type BaseFeuilleSaisie from '../feuilleSaisies/BaseFeuilleSaisie';
import BaseTypeRegroupement from './BaseTypeRegroupement';

class ArticleTypeRegroupement extends BaseTypeRegroupement {
  constructor() {
    super();
    this.code = TypeRegroupement.ARTICLE;
    this.codeColumnName = 'art_code';
    this.libelleColumnName = 'art_libelle';
    this.icon = {
      iconSet: 'Lucide',
      name: 'File',
    };
    this.libelle = 'Article';
  }

  getLibelle(data : TreeRow, feuille: BaseFeuilleSaisie): string {
    if (feuille.chapitreArticleLie && data.isordre) {
      return `${data.art_code} (${data.cha_code}) - ${data.art_libelle}`;
    }
    return super.getLibelle(data, feuille);
  }
}

export default ArticleTypeRegroupement;
