import TYPE_MONTANT_REGLE_CALCUL from '../../constants/simulation/typeMontantRegleCalcul';

const getTypeMontantText = (typeMontant: number, anneeExercice: number): string => {
  let text = '';
  let minus = 0;

  switch (typeMontant) {
    case TYPE_MONTANT_REGLE_CALCUL.BUDGET_TOTAL:
      text = 'Budget total';
      break;
    case TYPE_MONTANT_REGLE_CALCUL.BUDGET_TOTAL_N1:
      text = 'Budget total';
      minus = 1;
      break;
    case TYPE_MONTANT_REGLE_CALCUL.BUDGET_TOTAL_N2:
      text = 'Budget total';
      minus = 2;
      break;
    case TYPE_MONTANT_REGLE_CALCUL.BUDGET_PRIMITIF:
      text = 'Budget primitif';
      break;
    case TYPE_MONTANT_REGLE_CALCUL.BUDGET_PRIMITIF_N1:
      text = 'Budget primitif';
      minus = 1;
      break;
    case TYPE_MONTANT_REGLE_CALCUL.BUDGET_PRIMITIF_N2:
      text = 'Budget primitif';
      minus = 2;
      break;
    case TYPE_MONTANT_REGLE_CALCUL.BUDGET_SUPPLEMENTAIRE:
      text = 'Budget supplémentaire';
      break;
    case TYPE_MONTANT_REGLE_CALCUL.BUDGET_SUPPLEMENTAIRE_N1:
      text = 'Budget supplémentaire';
      minus = 1;
      break;
    case TYPE_MONTANT_REGLE_CALCUL.BUDGET_SUPPLEMENTAIRE_N2:
      text = 'Budget supplémentaire';
      minus = 2;
      break;
    case TYPE_MONTANT_REGLE_CALCUL.RESTES_A_REALISER_N1:
      text = 'Restes à réaliser';
      minus = 1;
      break;
    case TYPE_MONTANT_REGLE_CALCUL.DECISIONS_MODIFICATIVES:
      text = 'Décisions modificatives';
      break;
    case TYPE_MONTANT_REGLE_CALCUL.DECISIONS_MODIFICATIVES_N1:
      text = 'Décisions modificatives';
      minus = 1;
      break;
    case TYPE_MONTANT_REGLE_CALCUL.DECISIONS_MODIFICATIVES_N2:
      text = 'Décisions modificatives';
      minus = 2;
      break;
    case TYPE_MONTANT_REGLE_CALCUL.REALISE:
      text = 'Réalisé';
      break;
    case TYPE_MONTANT_REGLE_CALCUL.REALISE_N1:
      text = 'Réalisé';
      minus = 1;
      break;
    case TYPE_MONTANT_REGLE_CALCUL.REALISE_N2:
      text = 'Réalisé';
      minus = 2;
      break;
    default:
      break;
  }

  return anneeExercice ? `${text} ${anneeExercice - minus}` : `${text} N${minus === 0 ? '' : `-${minus}`}`;
};

export default getTypeMontantText;
