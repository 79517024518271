import type { Imputation } from '@jvs-group/jvs-mairistem-comptabilite';
import { request } from '@jvs-group/jvs-mairistem-store';
import type { CreateImputation } from '../../utils/imputation';

export const createImputation = (
  identifiantSimulation: number,
  imputation : CreateImputation,
) => request.post<unknown, Imputation>(
  `/api/finances/simulation/${identifiantSimulation}/createImputation`,
  imputation,
);

export default createImputation;
