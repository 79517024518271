import { formatMontant } from '@jvs-group/jvs-mairistem-finances-utils';
import BaseControleCoherence from './BaseControleCoherence';
import StatutControleCoherence from '../../../constants/simulation/statutControleCoherence';
import FamilleControleCoherence from '../../../constants/simulation/familleControleCoherence';
import type ChapitreSansExecutionBudgetaireData from '../../../interfaces/outil/controleCoherence/chapitreSansExecutionBudgetaireData';

class ChapitreSansExecutionBudgetaire extends BaseControleCoherence {
  data: ChapitreSansExecutionBudgetaireData;

  famille = FamilleControleCoherence.AUTRES;

  constructor(data: ChapitreSansExecutionBudgetaireData) {
    super(data);
    this.data = data;
  }

  getExplicatif(): string {
    const { depenseFonctionnement023, recetteInvestissement021, recetteInvestissement024 } = this.data;
    let explicatifs = '';

    switch (this.statut) {
      case StatutControleCoherence.ERROR:
        if (recetteInvestissement024 !== 0) {
          explicatifs += `${formatMontant(recetteInvestissement024, 2, true)} sur le chapitre 024 réalisés à tort.\n`;
        }
        if (recetteInvestissement021 !== 0) {
          explicatifs += `${formatMontant(recetteInvestissement021, 2, true)} sur le chapitre 021 réalisés à tort.\n`;
        }
        if (depenseFonctionnement023 !== 0) {
          explicatifs += `${formatMontant(depenseFonctionnement023, 2, true)} sur le chapitre 023 réalisés à tort.\n`;
        }

        return explicatifs;
      case StatutControleCoherence.OK:
        return 'Aucune réalisation recensée sur les chapitres 024, 021 et 023 : ils ont pour unique but de matérialiser le financement de la section d’investissement.';
      default:
        return '';
    }
  }

  getLibelle(): string {
    switch (this.statut) {
      case StatutControleCoherence.ERROR:
        return "Présence d'écritures sur les chapitres d’ordre ne donnant pas lieu à exécution budgétaire";
      case StatutControleCoherence.OK:
        return "Absence d'écritures sur les chapitres d’ordre ne donnant pas lieu à exécution budgétaire";
      default:
        return '';
    }
  }

  getStatut(data: ChapitreSansExecutionBudgetaireData): StatutControleCoherence {
    const { depenseFonctionnement023, recetteInvestissement021, recetteInvestissement024 } = data;

    if (depenseFonctionnement023 === 0 && recetteInvestissement021 === 0 && recetteInvestissement024 === 0) {
      return StatutControleCoherence.OK;
    }

    return StatutControleCoherence.ERROR;
  }
}

export default ChapitreSansExecutionBudgetaire;
