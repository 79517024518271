import { DoubleListSelector, getErrorMessage } from '@jvs-group/jvs-mairistem-finances-utils';
import React from 'react';
import { toast } from 'react-toastify';
import { isNil, maxBy } from 'lodash';
import type { DoubleListLineType }
  from '@jvs-group/jvs-mairistem-finances-utils/dist/ts/components/DoubleListSelector/DoubleListLine';
import type { Exercice } from '@jvs-group/jvs-mairistem-comptabilite';

import NoeudFeuilleModal from './NoeudFeuilleModal';
import type FeuilleSaisie from '../../../interfaces/feuilleSaisie/feuilleSaisie';
import {
  addFeuilleSaisie, deleteFeuilleSaisie, getFeuillesSaisies, updateFeuilleSaisie,
} from '../../../utils/feuilleSaisie/feuilleSaisie';
import FEUILLES_SAISIES from '../../../constants/feuilleSaisie/feuillesSaisies';

interface FeuilleSaisieListProps {
  exercice: Exercice
}

const FeuilleSaisieList = ({ exercice } : FeuilleSaisieListProps) => {
  const [feuillesSaisies, setFeuillesSaisies] = React.useState<FeuilleSaisie[]>([]);
  const [loadingFeuille, setLoadingFeuille] = React.useState(true);
  const [selectedFeuilleSaisie, setSelectedFeuilleSaisie] = React.useState<FeuilleSaisie>();

  const fetchFeuilleSaisie = async () => {
    try {
      setLoadingFeuille(true);
      setFeuillesSaisies(await getFeuillesSaisies(exercice));
    } catch (e) {
      toast.error('Impossible de charger les feuilles de saisies');
    } finally {
      setLoadingFeuille(false);
    }
  };

  React.useEffect(() => {
    if (!isNil(exercice)) {
      fetchFeuilleSaisie();
    }
  }, [exercice]);

  const rightValues = React.useMemo(() => feuillesSaisies?.map(({ code }) => ({
    identifiant: code,
    libelle: FEUILLES_SAISIES.find((f) => f.code === code)?.libelle ?? '',
  })), [feuillesSaisies]);

  const leftValues = React.useMemo(() => (FEUILLES_SAISIES?.reduce((previous, next) => {
    const isSelectedFeuille = feuillesSaisies?.some(((feuille) => (feuille?.code === next.code)));
    if (!isSelectedFeuille) {
      previous.push({
        identifiant: next.code,
        libelle: next.libelle,
      });
    }

    return previous;
  }, [])), [feuillesSaisies]);

  const handleAddItems = async (feuilles: DoubleListLineType[]) => {
    setLoadingFeuille(true);
    let i = maxBy(feuillesSaisies, (f) => f?.ordre)?.ordre ?? 0;
    const promises : Promise<FeuilleSaisie>[] = feuilles.map(
      async (feuille) => addFeuilleSaisie(exercice, feuille.identifiant, ++i),
    );
    Promise.all(promises).then((newFeuilles) => {
      setFeuillesSaisies((old) => [
        ...old,
        ...newFeuilles,
      ]);
    })
      .catch(() => toast.error("Impossible d'ajouter les feuilles de saisies"))
      .finally(() => setLoadingFeuille(false));
  };

  const handleDragDrop = async (result) => {
    let feuilles = [...feuillesSaisies];

    const destinationOrdre = feuilles[result.destination.index].ordre;
    const sourceOrdre = feuilles[result.source.index].ordre;

    if (result.destination.index > result.source.index) {
      for (let i = (result.source.index + 1); i <= result.destination.index; i++) {
        feuilles[i].ordre -= 1;
      }
    } else if (result.destination.index < result.source.index) {
      for (let i = (result.destination.index); i < result.source.index; i++) {
        feuilles[i].ordre += 1;
      }
    }

    feuilles[result.source.index].ordre = destinationOrdre;

    feuilles = feuilles.sort((a, b) => (a.ordre - b.ordre));

    setFeuillesSaisies(feuilles);

    try {
      await updateFeuilleSaisie({
        ...feuilles[result.destination.index],
        ancienOrdre: sourceOrdre,
      });
    } catch (e) {
      toast.error(getErrorMessage(e, "L'ordre n'a pas pu être appliqué"));
    }
  };

  const handleDeleteFeuille = async (identifiant: string) => {
    const feuille = feuillesSaisies.find((f) => f.code === identifiant);

    await deleteFeuilleSaisie(feuille.identifiant);
    setFeuillesSaisies((old) => old.filter((f) => f.identifiant !== feuille.identifiant));
  };

  const handleSelectFeuille = (identifiant: string) => {
    setSelectedFeuilleSaisie(feuillesSaisies.find((f) => f.code === identifiant));
  };

  const handleFeuilleSave = (feuille: FeuilleSaisie) => {
    updateFeuilleSaisie(feuille);
    setFeuillesSaisies(
      (old) => old.map(
        (feuilleSaisie) => (feuilleSaisie?.identifiant === feuille?.identifiant ? feuille : feuilleSaisie),
      ),
    );
    setSelectedFeuilleSaisie(null);
  };

  const handleCloseModal = () => {
    setSelectedFeuilleSaisie(null);
  };

  return (
    <>
      <DoubleListSelector
        leftValues={leftValues}
        rightValues={rightValues}
        leftTitle="Feuilles de saisie non-affectées"
        rightTitle="Feuilles de saisie affectées"
        iconLabel="feuille(s) à affecter"
        onDeleteLine={handleDeleteFeuille}
        onAddItems={handleAddItems}
        onDragDrop={handleDragDrop}
        loading={loadingFeuille}
        additionnalLineActions={[
          { icon: 'setting', onClick: handleSelectFeuille },
        ]}
      />

      <NoeudFeuilleModal
        feuilleSaisie={selectedFeuilleSaisie}
        onFeuilleSave={handleFeuilleSave}
        onClose={handleCloseModal}
        exercice={exercice}
      />
    </>
  );
};

export default FeuilleSaisieList;
