import {
  PANEL_TYPE,
  type PinnedPanel,
  type TemplateRequestGet,
} from '@jvs-group/jvs-mairistem-finances-utils';
import { request } from '@jvs-group/jvs-mairistem-store';

const url = '/api/finances/comptabilite/piecescomptables/pinnedPanel';

export const addPinnedPanel = async (code: string, identifiantEntite: number) => request.post(url, {
  code,
  identifiantEntite,
  type: PANEL_TYPE.SIMULATION,
});

export const deletePinnedPanel = async (code: string, identifiantEntite: number) => {
  const parameters = new URLSearchParams();
  parameters.append('code', code);
  parameters.append('identifiantEntite', identifiantEntite.toString());
  parameters.append('type', PANEL_TYPE.SIMULATION.toString());

  return request.delete(`${url}?${parameters?.toString()}`);
};

export const getPinnedPanel = async (identifiantEntite: number) => {
  const parameters = new URLSearchParams();
  parameters.append('identifiantEntite', identifiantEntite.toString());
  parameters.append('type', PANEL_TYPE.SIMULATION.toString());

  return request.get<unknown, TemplateRequestGet<PinnedPanel>>(`${url}?${parameters.toString()}`);
};
