import { request } from '@jvs-group/jvs-mairistem-store';
import type { TemplateRequestGet } from '@jvs-group/jvs-mairistem-finances-utils';
import type { Exercice } from '@jvs-group/jvs-mairistem-comptabilite';
import type BaseFeuilleSaisie from '../../classes/feuilleSaisies/BaseFeuilleSaisie';
import TypeRegroupement from '../../constants/feuilleSaisie/typeRegroupement';
import FEUILLES_SAISIES from '../../constants/feuilleSaisie/feuillesSaisies';
import type FeuilleSaisie from '../../interfaces/feuilleSaisie/feuilleSaisie';

const getNoeudFeuille = (feuille: BaseFeuilleSaisie) => {
  const noeuds = [];
  // le premier noeud n est pas sauvegarder en base
  for (let i = 1; i < feuille.nodes.length; i++) {
    for (let j = 0; j < feuille.nodes[i].length; j++) {
      if (feuille.nodes[i][j].code !== TypeRegroupement.IMPUTATION) {
        noeuds.push({
          code: feuille.nodes[i][j].code,
          ordre: i + j,
        });
      }
    }
  }

  return noeuds;
};

export const getFeuillesSaisies = async (exercice: Exercice) => request.get<unknown, TemplateRequestGet<FeuilleSaisie>>(
  `/api/finances/simulation/feuilleSaisie/?all=true&withNoeuds=true&identifiantEntite=${exercice.identifiantEntite}`,
).then((d) => d.data);

export const deleteFeuilleSaisie = (identifiant: number) => request.delete<unknown, TemplateRequestGet<FeuilleSaisie>>(
  `/api/finances/simulation/feuilleSaisie/${identifiant}`,
);

export const addFeuilleSaisie = async (exercice: Exercice, code: string, ordre: number) => {
  const feuille = FEUILLES_SAISIES.find((f) => f.code === code);
  return request.post<unknown, FeuilleSaisie>(
    '/api/finances/simulation/feuilleSaisie',
    {
      code,
      ordre,
      codeFinArbo: feuille.defaultCodeFinArbo,
      chapitreArticleLie: feuille.chapitreArticleLie,
      identifiantEntite: exercice.identifiantEntite,
      noeuds: getNoeudFeuille(feuille),
    },
  );
};

export const updateFeuilleSaisie = (feuille: FeuilleSaisie) => request.put<unknown, FeuilleSaisie>(
  `/api/finances/simulation/feuilleSaisie/${feuille.identifiant}`,
  feuille,
);
