import { formatMontant } from '@jvs-group/jvs-mairistem-finances-utils';
import BaseControleCoherence from './BaseControleCoherence';
import TypeBudget from '../../../constants/simulation/typeBudget';
import FamilleControleCoherence from '../../../constants/simulation/familleControleCoherence';
import StatutControleCoherence from '../../../constants/simulation/statutControleCoherence';
import type EquilibreOperationOrdreInterieurSectionFonctionnementData from '../../../interfaces/outil/controleCoherence/equilibreOperationOrdreInterieurSectionFonctionnementData';

class EquilibreOperationOrdreInterieurSectionFonctionnement extends BaseControleCoherence {
  data: EquilibreOperationOrdreInterieurSectionFonctionnementData;

  famille = FamilleControleCoherence.OPERATIONS_ORDRE;

  constructor(data: EquilibreOperationOrdreInterieurSectionFonctionnementData) {
    super(data);
    this.data = data;
  }

  getExplicatif(typeBudget: TypeBudget): string {
    const depenseFonctionnement043 = formatMontant(this.data.depenseFonctionnement043, 2, true);
    const recetteFonctionnement043 = formatMontant(this.data.recetteFonctionnement043, 2, true);

    switch (this.statut) {
      case StatutControleCoherence.ERROR:
        if (typeBudget === TypeBudget.COMPTE_ADMINISTRATIF) {
          return `Le total des dépenses de fonctionnement réalisées au chapitre 043 (${depenseFonctionnement043}) diffère des recettes de fonctionnement réalisées au 043 (${recetteFonctionnement043}).`;
        }
        return `Le total des prévisions budgétaires inscrites en dépenses de fonctionnement au chapitre 043 (${depenseFonctionnement043}) diffère des recettes de fonctionnement inscrites au 043 (${recetteFonctionnement043}).`;
      case StatutControleCoherence.OK:
        if (typeBudget === TypeBudget.COMPTE_ADMINISTRATIF) {
          return `Le total des dépenses de fonctionnement réalisées au chapitre 043 est égal aux recettes de fonctionnement réalisées au 043 (${depenseFonctionnement043}).`;
        }
        return `Le total des prévisions budgétaires inscrites en dépenses de fonctionnement au chapitre 043 est égal aux recettes de fonctionnement inscrites au 043 (${depenseFonctionnement043}).`;
      default:
        return '';
    }
  }

  getLibelle(): string {
    switch (this.statut) {
      case StatutControleCoherence.ERROR:
        return "Déséquilibre des opérations d’ordre à l'intérieur de la section de fonctionnement";
      case StatutControleCoherence.OK:
        return "Équilibre des opérations d’ordre à l'intérieur de la section de fonctionnement";
      default:
        return '';
    }
  }

  getStatut(data: EquilibreOperationOrdreInterieurSectionFonctionnementData): StatutControleCoherence {
    const { depenseFonctionnement043, recetteFonctionnement043 } = data;

    if (depenseFonctionnement043 === recetteFonctionnement043) {
      return StatutControleCoherence.OK;
    }

    return StatutControleCoherence.ERROR;
  }
}

export default EquilibreOperationOrdreInterieurSectionFonctionnement;
