import type { TemplateRequestGet } from '@jvs-group/jvs-mairistem-finances-utils';
import { request } from '@jvs-group/jvs-mairistem-store';
import type RegleCalcul from '../../interfaces/simulation/regleCalcul';

const url = '/api/finances/simulation/reglesCalcul';

export const createRegleCalcul = (regleCalcul: RegleCalcul) => request.post<RegleCalcul, RegleCalcul>(url, regleCalcul);

export const deleteRegleCalcul = (identifiant: number) => request.delete<unknown, unknown>(`${url}/${identifiant}`);

export const getReglesCalcul = async (filters?: object) => {
  const params = new URLSearchParams();

  Object.entries(filters).forEach(([key, value]) => {
    params.append(key, value);
  });

  return request.get<any, TemplateRequestGet<RegleCalcul>>(`${url}?${params.toString()}`);
};

export const updateRegleCalcul = (regleCalcul: RegleCalcul) => request.put<RegleCalcul, RegleCalcul>(
  `${url}/${regleCalcul.identifiant}`,
  regleCalcul,
);
