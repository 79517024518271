import React from 'react';
import {
  Accordion,
  Form,
  Input,
  Menu,
  Tab,
} from '@jvs-group/jvs-mairistem-composants';
import { isNil } from 'lodash';
import { toast } from 'react-toastify';
import { OptionsSelectionAvancees, typeNumber, typeString } from '@jvs-group/jvs-mairistem-liste';
import { Combobox, Icon } from '@jvs-group/jvs-mairistem-finances-utils';
import TreeViewFooter from './TreeViewFooter';
import TreeViewPlaceholder from './TreeViewPlaceholder';
import TreeViewFeuilleSaisie from './TreeViewFeuilleSaisie';
import 'rsuite-table/lib/less/index.less';
import './TreeView.less';
import type Filters from '../../interfaces/simulation/filters';
import type Simulation from '../../interfaces/simulation/simulation';
import UserRole from '../../constants/common/userRole';
import type TreeRow from '../../interfaces/feuilleSaisie/treeRow';
import ShowOnly from '../../constants/feuilleSaisie/showOnly';
import FeuilleSaisieCode from '../../constants/feuilleSaisie/feuilleSaisieCode';
import type BaseFeuilleSaisie from '../../classes/feuilleSaisies/BaseFeuilleSaisie';
import { addFeuilleSaisie, getFeuillesSaisies } from '../../utils/feuilleSaisie/feuilleSaisie';
import FeuilleSaisieFactory from '../../classes/feuilleSaisies/FeuilleSaisieFactory';
import { fetchData } from '../../utils/feuilleSaisie/imputation';
import ImputationTable from '../simulation/ImputationTable';
import TypePeriode from '../../constants/simulation/typePeriode';
import FEUILLES_SAISIES from '../../constants/feuilleSaisie/feuillesSaisies';

interface TreeViewProps {
  filters: Filters;
  onChangeMontant: (montantObj: object, rowData: TreeRow) => void;
  onRefresh: () => Promise<void>;
  setFilters: React.Dispatch<any>;
  simulation: Simulation;
  userRole?: UserRole;
}

const VISIBLE_PANE_COUNT = 9;

const options = [
  {
    'data-testid': 'optionToutesLignes',
    key: ShowOnly.TOUTES_LIGNES,
    text: 'Afficher toutes les lignes',
    value: ShowOnly.TOUTES_LIGNES,
  },
  {
    'data-testid': 'optionLignesAvecMontant',
    key: ShowOnly.LIGNES_AVEC_MONTANT,
    text: 'Afficher uniquement les lignes avec montant',
    value: ShowOnly.LIGNES_AVEC_MONTANT,
  },
  {
    'data-testid': 'optionLignesSaisies',
    key: ShowOnly.LIGNES_SAISIES,
    text: 'Afficher uniquement les lignes saisies',
    value: ShowOnly.LIGNES_SAISIES,
  },
  {
    'data-testid': 'optionLignesNegativesSaisies',
    key: ShowOnly.LIGNES_NEGATIVES_SAISIES,
    text: 'Afficher uniquement les lignes négatives saisies',
    value: ShowOnly.LIGNES_NEGATIVES_SAISIES,
  },
];

const TreeView = ({
  filters,
  onChangeMontant,
  onRefresh,
  setFilters,
  simulation,
  userRole = UserRole.NORMAL,
}: TreeViewProps) => {
  const [data, setData] = React.useState<TreeRow[]>([]);
  const [feuilleSaisie, setFeuilleSaisie] = React.useState<BaseFeuilleSaisie>();
  const [feuillesSaisies, setFeuillesSaisies] = React.useState<BaseFeuilleSaisie[]>();
  const [feuilleTabLoading, setFeuilleTabLoading] = React.useState<boolean>(false);
  const [indexPaneFirstNode, setIndexPaneFirstNode] = React.useState<number>(0);
  const [indexPaneVisible, setIndexPaneVisible] = React.useState<number>(0);
  const [isFocusingInput, setIsFocusingInput] = React.useState<boolean>(false);
  const [loadingPane, setLoadingPane] = React.useState<boolean>(false);

  const fetchFeuillesSaisies = async () => {
    const listFeuille: BaseFeuilleSaisie[] = [];
    let feuilles = await getFeuillesSaisies(simulation?.exercice);

    if (feuilles.length === 0) {
      feuilles = await Promise.all([
        addFeuilleSaisie(simulation?.exercice, FeuilleSaisieCode.FONCTIONNEMENT, 1),
        addFeuilleSaisie(simulation?.exercice, FeuilleSaisieCode.INVESTISSEMENT_PAR_OPERATION, 2),
        addFeuilleSaisie(simulation?.exercice, FeuilleSaisieCode.OPERATION_ORDRE_BUDGETAIRE, 3),
      ]);
    }

    feuilles?.forEach((feuille) => {
      const f = FeuilleSaisieFactory.newInstance(feuille, simulation?.exercice);
      if (f.isVisible(simulation)) {
        listFeuille.push(FeuilleSaisieFactory.newInstance(feuille, simulation?.exercice));
      }
    });
    setFeuillesSaisies(listFeuille);
    setFeuilleSaisie(listFeuille[0]);
  };

  React.useEffect(() => {
    if (simulation?.exercice) fetchFeuillesSaisies();
  }, [simulation?.exercice]);

  React.useEffect(() => {
    if (simulation?.typePeriode) {
      setIndexPaneVisible(0);
    }
  }, [simulation?.typePeriode]);

  const getTabFilter = (data, activeIndex: number, feuille: BaseFeuilleSaisie) => {
    if (!feuille) {
      return null;
    }

    const baseTypeRegroupement = feuille.nodes[0][0];

    const result = {
      [baseTypeRegroupement.code]: data?.[activeIndex]?.[baseTypeRegroupement.codeColumnName] ?? null,
    };

    if (!isNil(baseTypeRegroupement.libelleColumnName)) {
      // eslint-disable-next-line max-len
      result[baseTypeRegroupement.libelleColumnName] = data?.[activeIndex]?.[baseTypeRegroupement?.libelleColumnName] ?? null;
    }

    return result;
  };

  const loadTabData = React.useCallback(async (data, activeIndex: number, feuille: BaseFeuilleSaisie) => {
    if (data[activeIndex].children.length > 0) {
      return;
    }

    const newData = [...data];
    // recuperation des données du treeview
    newData[activeIndex].children = await fetchData(
      simulation,
      feuille,
      feuille.nodes[1],
      {
        ...getTabFilter(data, activeIndex, feuille),
        ...filters,
      },
    );

    setData(newData);
  }, [simulation?.identifiant, filters]);

  const fetchFeuilleData = React.useCallback(async (index) => {
    if (!isNil(feuilleSaisie) && !isNil(simulation?.identifiant)) {
      // recuperation du premier noeud en tab
      const newData = await fetchData(simulation, feuilleSaisie, feuilleSaisie.nodes[0], filters);
      if (newData?.length === 0) {
        toast.info('Aucune ligne budgétaire répond à votre sélection');
      }
      loadTabData(newData, index, feuilleSaisie);
    }
  }, [simulation?.identifiant, feuilleSaisie, filters]);

  // Handler qui va mettre a jour les donnée de la treeview d'une tab
  const handleTabDataChange = (tabData: TreeRow[]) => {
    const newData = [...data];
    newData[indexPaneVisible + indexPaneFirstNode].children = tabData;
    setData(newData);
  };

  const handleFeuilleChange = React.useCallback(async () => {
    setLoadingPane(true);
    await fetchFeuilleData(0);
    setLoadingPane(false);
  }, [fetchFeuilleData]);

  // Au changement de ma feuille de saisie, je charge les données
  React.useEffect(() => {
    setData([]);
    setIndexPaneFirstNode(0);
    handleFeuilleChange();
  }, [handleFeuilleChange, feuilleSaisie]);

  const handleTabFirstNodeChange = async (e, d) => {
    if (loadingPane || d.activeIndex === indexPaneFirstNode) {
      return;
    }

    setLoadingPane(true);
    setIndexPaneFirstNode(d.activeIndex);
    await loadTabData(data, indexPaneVisible + d.activeIndex, feuilleSaisie);
    setLoadingPane(false);
  };

  const handleTabScrollPrevious = (e) => {
    e.stopPropagation();
    const nextIndexVisible = indexPaneVisible - VISIBLE_PANE_COUNT;
    setIndexPaneVisible(nextIndexVisible);
    loadTabData(data, nextIndexVisible, feuilleSaisie);
    setIndexPaneFirstNode(0);
  };

  const handleTabScrollNext = (e) => {
    e.stopPropagation();
    const nextIndexVisible = indexPaneVisible + VISIBLE_PANE_COUNT;
    setIndexPaneVisible(nextIndexVisible);
    loadTabData(data, nextIndexVisible, feuilleSaisie);
    setIndexPaneFirstNode(0);
  };

  const renderFirstNodePanes = React.useCallback(() => {
    if (data?.length === 0 || !feuilleSaisie) {
      return null;
    }

    const node = feuilleSaisie.nodes[0][0];
    const panes = [];
    const end = Math.min((indexPaneVisible + VISIBLE_PANE_COUNT - 1), (data.length - 1));
    for (let i = indexPaneVisible; i <= end; i++) {
      const currentData = data[i];
      const key = node.getKey(currentData);

      panes.push({
        menuItem: (
          <Menu.Item
            className="tabHeader"
            data-testid={`firstNode${key}`}
            disabled={isFocusingInput}
            key={i + key}
          >
            {(i === indexPaneVisible && indexPaneVisible > 0) && (
              <Icon
                className="tab-arrow"
                data-testid="previousNode"
                link
                name="arrow circle left"
                onClick={handleTabScrollPrevious}
              />
            )}

            <div className="content" title={node.getLibelle(data[i], feuilleSaisie)}>
              {node.getTabLibelle(currentData)}
            </div>

            {(i === (end) && end !== (data.length - 1)) && (
              <Icon
                className="tab-arrow end"
                data-testid="nextNode"
                link
                name="arrow circle right"
                onClick={handleTabScrollNext}
              />
            ) }
          </Menu.Item>
        ),
        render: () => (
          <Tab.Pane key={i + key}>
            <ImputationTable
              data={currentData.children}
              key={key}
              feuilleSaisie={feuilleSaisie}
              handleDataChange={handleTabDataChange}
              filters={{
                ...getTabFilter(data, indexPaneVisible + indexPaneFirstNode, feuilleSaisie),
                ...filters,
              }}
              onChangeMontant={onChangeMontant}
              setIsFocusingInput={setIsFocusingInput}
              simulation={simulation}
              userRole={userRole}
              onRefresh={onRefresh}
            />
            {
              feuilleSaisie?.isFooterVisible() && (
                <TreeViewFooter
                  data={currentData}
                  typeRegroupement={feuilleSaisie.nodes[1][0]}
                  feuilleSaisie={feuilleSaisie}
                  simulation={simulation}
                />
              )
            }
          </Tab.Pane>
        ),
      });
    }
    return panes;
  }, [
    data,
    feuilleSaisie,
    filters,
    indexPaneFirstNode,
    indexPaneVisible,
    isFocusingInput,
    simulation?.typePeriode,
  ]);

  const handleTabFeuilleChange = async (e, { code }) => {
    if (code === feuilleSaisie.code) {
      return;
    }
    const feuille = feuillesSaisies.find((feuille) => feuille.code === code);
    setData([]);
    setIndexPaneVisible(0);
    setIndexPaneFirstNode(0);
    setFeuilleSaisie(feuille);
  };

  const handleFullTextSearchChange = (e, { value }) => {
    setIndexPaneVisible(0);
    setFilters((old) => ({ ...old, q: value }));
  };

  const handleSearchMontant = (e, { value }: { value: string }) => {
    setIndexPaneVisible(0);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setFilters(({ montant, ...rest }) => ({
      ...rest,
      ...(value && { montant: value }),
    }));
  };

  const handleOptionChange = (e, { value }) => {
    setIndexPaneVisible(0);
    setFilters((old) => ({ ...old, showOnly: value }));
  };

  const handleFeuilleSelect = async (f: BaseFeuilleSaisie) => {
    setFeuilleTabLoading(true);
    setIndexPaneVisible(0);
    setIndexPaneFirstNode(0);
    try {
      const newFeuille = await addFeuilleSaisie(simulation?.exercice, f.code, feuillesSaisies.length);

      const feuille = FeuilleSaisieFactory.newInstance(newFeuille, simulation?.exercice);
      setFeuillesSaisies((old) => [...old, feuille]);
      setFeuilleSaisie(feuille);
    } catch {
      toast.error("Erreur lors de l'ajout de la feuille de saisie");
    } finally {
      setFeuilleTabLoading(false);
    }
  };

  const handleFiltresAvancesChange = (e, filtresAvances) => {
    setIndexPaneVisible(0);
    setFilters((old) => ({
      ...old,
      filtresAvances: JSON.stringify(filtresAvances.map(({
        id, value, type, condition,
      }) => ({
        id,
        value,
        type,
        condition,
      }))),
    }));
  };

  const renderFiltresAvancee = () => {
    const codes = [
      {
        id: 'cha_code',
        libelle: 'Code chapitre',
        type: typeString,
      },
      {
        id: 'art_code',
        libelle: 'Code article',
        type: typeString,
      },
      {
        id: 'ope_code',
        libelle: 'Code opération',
        type: typeString,
      },
    ];

    const libelles = [
      {
        id: 'cha_libelle',
        libelle: 'Libellé chapitre',
        type: typeString,
      },
      {
        id: 'art_libelle',
        libelle: 'Libellé article',
        type: typeString,
      },
      {
        id: 'ope_libelle',
        libelle: 'Libellé opération',
        type: typeString,
      },
    ];

    if ((simulation?.exercice?.gestionVentilation && !simulation?.exercice?.venExec)) {
      codes.push(
        {
          id: 'ven_code',
          libelle: 'Code ventilation',
          type: typeString,
        },
      );

      libelles.push(
        {
          id: 'ven_libelle',
          libelle: 'Libellé ventilation',
          type: typeString,
        },
      );
    }

    if (simulation?.exercice?.gestionAnalytique && !simulation?.exercice?.anaExec) {
      codes.push(
        {
          id: 'ana_code',
          libelle: 'Code analytique',
          type: typeString,
        },
      );

      libelles.push(
        {
          id: 'ana_libelle',
          libelle: 'Libellé analytique',
          type: typeString,
        },
      );
    }

    if ((simulation?.exercice?.gestionFonctionnelle)) {
      codes.push(
        {
          id: 'fon_code',
          libelle: 'Code fonction',
          type: typeString,
        },
      );

      libelles.push(
        {
          id: 'fon_libelle',
          libelle: 'Libellé fonction',
          type: typeString,
        },
      );
    }
    const items = [
      {
        id: 'cha_section',
        libelle: 'Section',
        type: typeString,
        choices: [
          {
            key: 'I',
            text: 'Investissement',
            value: 'I',
          },
          {
            key: 'F',
            text: 'Fonctionnement',
            value: 'F',
          },
        ],
      },
      {
        id: 'imp_sens',
        libelle: 'Sens',
        type: typeString,
        choices: [
          {
            key: 'D',
            text: 'Dépense',
            value: 'D',
          },
          {
            key: 'R',
            text: 'Recette',
            value: 'R',
          },
        ],
      },
      ...codes,
      ...libelles,
      {
        id: 'mtsi_commentaire',
        libelle: 'Commentaire',
        type: typeString,
      },
      {
        id: 'imsi_prop',
        libelle: 'Proposé',
        type: typeNumber,
      },
      {
        id: 'imsi_vote',
        libelle: 'Voté',
        type: typeNumber,
      },
      {
        id: 'imsi_rep',
        libelle: 'Reporté',
        type: typeNumber,
      },
    ];

    return (
      <div>
        <OptionsSelectionAvancees
          // @ts-expect-error
          basic
          eventsEnabled={false}
          items={items}
          key="filters"
          popper={{
            class: 'filtresAvances',
          }}
          sortable={false}
          onSave={handleFiltresAvancesChange}
        />
      </div>
    );
  };

  const getPlaceholderRechercheMontant = () => {
    switch (simulation?.typePeriode) {
      case TypePeriode.DEMANDE:
        return 'Rechercher un montant demandé';
      case TypePeriode.PROPOSE:
        return 'Rechercher un montant proposé';
      case TypePeriode.VOTE:
        return 'Rechercher un montant voté';
      default:
        return 'Rechercher un montant';
    }
  };

  const handleKeyDownDisableEnter = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
    }
  };

  return (
    <Accordion className="fiche-panel-header segment">
      <Accordion.Content active className="treeViewAccordionContent">
        <Menu
          className="menuFeuilleSaisie"
          pointing
          secondary
        >
          { feuillesSaisies?.map((feuille) => (
            <Menu.Item
              active={feuille.code === feuilleSaisie.code}
              code={feuille.code}
              data-testid={`feuilleSaisieMenuItem${feuille.code}`}
              content={feuille.libelle}
              onClick={handleTabFeuilleChange}
            />
          ))}
          {
            (userRole === UserRole.ADMIN
              && feuillesSaisies?.length < 8
              && FEUILLES_SAISIES.length !== feuillesSaisies.length
            ) && (
              <TreeViewFeuilleSaisie
                feuillesSaisies={feuillesSaisies}
                loading={feuilleTabLoading}
                onSelect={handleFeuilleSelect}
                simulation={simulation}
              />
            )
          }
        </Menu>
        <Form>
          <Form.Group className="treeViewBar">
            <Form.Field
              control={Input}
              debounceTime={1000}
              input={{ 'data-testid': 'fullTextSearchInput' }}
              onChange={handleFullTextSearchChange}
              value={filters?.q}
              onKeyDown={handleKeyDownDisableEnter}
              placeholder="Rechercher en saisissant un libellé, un commentaire ..."
              width={9}
            />

            <Form.Field
              control={Input}
              debounceTime={1000}
              input={{ 'data-testid': 'montantSearchInput', type: 'number' }}
              onChange={handleSearchMontant}
              onKeyDown={handleKeyDownDisableEnter}
              placeholder={getPlaceholderRechercheMontant()}
              value={filters?.montant}
              width={4}
            />

            <Form.Field>
              {renderFiltresAvancee()}
            </Form.Field>

            <Form.Field
              control={Combobox}
              data-testid="optionsDropdown"
              defaultValue={ShowOnly.TOUTES_LIGNES}
              onChange={handleOptionChange}
              options={options}
              selection
              value={filters?.showOnly}
              width={3}
            />
          </Form.Group>
        </Form>
        {loadingPane
          ? <TreeViewPlaceholder />
          : (
            <Tab
              activeIndex={indexPaneFirstNode}
              menu={{ pointing: true, secondary: true }}
              onTabChange={handleTabFirstNodeChange}
              panes={renderFirstNodePanes()}
            />
          )}
      </Accordion.Content>
    </Accordion>
  );
};

export default TreeView;
