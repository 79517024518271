import TypeRegroupement from '../../constants/feuilleSaisie/typeRegroupement';
import AnalytiqueTypeRegroupement from './AnalytiqueTypeRegroupement';
import ArticleTypeRegroupement from './ArticleTypeRegroupement';
import type BaseTypeRegroupement from './BaseTypeRegroupement';
import ChapitreTypeRegroupement from './ChapitreTypeRegroupement';
import FonctionTypeRegroupement from './FonctionTypeRegroupement';
import ImputationTypeRegroupement from './ImputationTypeRegroupement';
import OperationTypeRegroupement from './OperationTypeRegroupement';
import SectionTypeRegroupement from './SectionTypeRegroupement';
import SensTypeRegroupement from './SensTypeRegroupement';
import VentilationTypeRegroupement from './VentilationTypeRegroupement';

class TypeRegroupementFactory {
  static newInstance(code: string, chapitreArticleLie: boolean = false): BaseTypeRegroupement {
    switch (code) {
      case TypeRegroupement.ANALYTIQUE:
        return new AnalytiqueTypeRegroupement();
      case TypeRegroupement.ARTICLE:
        return new ArticleTypeRegroupement();
      case TypeRegroupement.CHAPITRE:
        return new ChapitreTypeRegroupement(chapitreArticleLie);
      case TypeRegroupement.FONCTION:
        return new FonctionTypeRegroupement();
      case TypeRegroupement.IMPUTATION:
        return new ImputationTypeRegroupement();
      case TypeRegroupement.OPERATION:
        return new OperationTypeRegroupement();
      case TypeRegroupement.SECTION:
        return new SectionTypeRegroupement();
      case TypeRegroupement.SENS:
        return new SensTypeRegroupement();
      case TypeRegroupement.VENTILATION:
        return new VentilationTypeRegroupement();
      default:
        return null;
    }
  }
}

export default TypeRegroupementFactory;
