import { formatMontant } from '@jvs-group/jvs-mairistem-finances-utils';
import BaseControleCoherence from './BaseControleCoherence';
import FamilleControleCoherence from '../../../constants/simulation/familleControleCoherence';
import StatutControleCoherence from '../../../constants/simulation/statutControleCoherence';
import type PlafondDepenseImprevuFonctionnementData from '../../../interfaces/outil/controleCoherence/plafondDepenseImprevuFonctionnementData';

class PlafondDepenseImprevuFonctionnement extends BaseControleCoherence {
  data: PlafondDepenseImprevuFonctionnementData;

  famille = FamilleControleCoherence.AUTRES;

  constructor(data: PlafondDepenseImprevuFonctionnementData) {
    super(data);
    this.data = data;
  }

  getExplicatif(): string {
    const depenseFonctionnement022 = formatMontant(this.data.depenseFonctionnement022, 2, true);
    const { depenseFonctionnementReelle } = this.data;

    switch (this.statut) {
      case StatutControleCoherence.ERROR:
        return `Les crédits inscrits au 022 (${depenseFonctionnement022}) sont strictement supérieur aux 7,5% des dépenses réelles de fonctionnement hors restes à réaliser (${depenseFonctionnementReelle} x 7,5% = ${formatMontant(depenseFonctionnementReelle * 0.075, 2, true)}).`;
      case StatutControleCoherence.OK:
        return `Les crédits inscrits au 022 (${depenseFonctionnement022}) sont inférieurs aux 7,5% des dépenses réelles de fonctionnement hors restes à réaliser (${depenseFonctionnementReelle} x 7,5% = ${formatMontant(depenseFonctionnementReelle * 0.075, 2, true)}).`;
      default:
        return '';
    }
  }

  getLibelle(): string {
    switch (this.statut) {
      case StatutControleCoherence.ERROR:
        return 'Non-respect du plafond des dépenses imprévues en fonctionnement';
      case StatutControleCoherence.OK:
        return 'Respect du plafond des dépenses imprévues en fonctionnement';
      default:
        return '';
    }
  }

  getStatut(data: PlafondDepenseImprevuFonctionnementData): StatutControleCoherence {
    const { depenseFonctionnement022, depenseFonctionnementReelle } = data;

    // DF_022 <= 7,5% de depenseFonctionnementReelle
    if (depenseFonctionnement022 <= (depenseFonctionnementReelle * 0.075)) {
      return StatutControleCoherence.OK;
    }

    return StatutControleCoherence.ERROR;
  }
}

export default PlafondDepenseImprevuFonctionnement;
