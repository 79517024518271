import { formatMontant } from '@jvs-group/jvs-mairistem-finances-utils';
import BaseControleCoherence from './BaseControleCoherence';
import FamilleControleCoherence from '../../../constants/simulation/familleControleCoherence';
import StatutControleCoherence from '../../../constants/simulation/statutControleCoherence';
import type EquilibreReelData from '../../../interfaces/outil/controleCoherence/equilibreReelData';

class EquilibreReel extends BaseControleCoherence {
  data: EquilibreReelData;

  famille = FamilleControleCoherence.EQUILIBRE_BUDGETAIRE;

  constructor(data: EquilibreReelData) {
    super(data);
    this.data = data;
  }

  getExplicatif(): string {
    const annuite = formatMontant(this.data.annuite, 2, true);
    const ressources = formatMontant(this.data.ressources, 2, true);

    switch (this.statut) {
      case StatutControleCoherence.ERROR:
        return `Le remboursement de l’annuité en capital de la dette (${annuite}) n'est pas couvert par les ressources propres de la collectivité (${ressources}).`;
      case StatutControleCoherence.OK:
        return `Le remboursement de l’annuité en capital de la dette (${annuite}) est couvert par les ressources propres de la collectivité (${ressources}).`;
      default:
        return '';
    }
  }

  getLibelle(): string {
    switch (this.statut) {
      case StatutControleCoherence.ERROR:
        return "Non-respect de l'équilibre réel";
      case StatutControleCoherence.OK:
        return "Respect de l'équilibre réel";
      default:
        return '';
    }
  }

  getStatut(data: EquilibreReelData): StatutControleCoherence {
    const { annuite, ressources } = data;

    if (ressources >= annuite) {
      return StatutControleCoherence.OK;
    }

    return StatutControleCoherence.ERROR;
  }
}

export default EquilibreReel;
