import FeuilleSaisieCode from '../../constants/feuilleSaisie/feuilleSaisieCode';
import TypeRegroupement from '../../constants/feuilleSaisie/typeRegroupement';
import TypeBudget from '../../constants/simulation/typeBudget';
import type Simulation from '../../interfaces/simulation/simulation';
import AnalytiqueTypeRegroupement from '../typeRegroupement/AnalytiqueTypeRegroupement';
import ArticleTypeRegroupement from '../typeRegroupement/ArticleTypeRegroupement';
import ChapitreTypeRegroupement from '../typeRegroupement/ChapitreTypeRegroupement';
import FonctionTypeRegroupement from '../typeRegroupement/FonctionTypeRegroupement';
import ImputationTypeRegroupement from '../typeRegroupement/ImputationTypeRegroupement';
import SensTypeRegroupement from '../typeRegroupement/SensTypeRegroupement';
import VentilationTypeRegroupement from '../typeRegroupement/VentilationTypeRegroupement';
import BaseFeuilleSaisie from './BaseFeuilleSaisie';

class FonctionnementFeuilleSaisie extends BaseFeuilleSaisie {
  constructor() {
    super();
    this.code = FeuilleSaisieCode.FONCTIONNEMENT;
    this.isCreditReporteShown = true;
    this.section = 'F';
    this.libelle = 'Fonctionnement';
    this.defaultCodeFinArbo = TypeRegroupement.ARTICLE;
    this.nodes = [
      [new SensTypeRegroupement()],
      [new ChapitreTypeRegroupement()],
      [new ArticleTypeRegroupement()],
      [new FonctionTypeRegroupement()],
      [new AnalytiqueTypeRegroupement()],
      [new VentilationTypeRegroupement(), new ImputationTypeRegroupement()],
    ];

    this.icon = {
      iconSet: 'Lucide',
      name: 'BatteryCharging',
    };
  }

  isVisible(simulation: Simulation): boolean {
    return simulation?.budget?.type !== TypeBudget.COMPTE_ADMINISTRATIF || simulation?.exercice?.budReportFonc;
  }
}

export default FonctionnementFeuilleSaisie;
