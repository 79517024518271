import { formatMontant } from '@jvs-group/jvs-mairistem-finances-utils';
import BaseControleCoherence from './BaseControleCoherence';
import FamilleControleCoherence from '../../../constants/simulation/familleControleCoherence';
import TypeBudget from '../../../constants/simulation/typeBudget';
import StatutControleCoherence from '../../../constants/simulation/statutControleCoherence';
import type EquilibreOperationOrdreTransfertFonctionnementData from '../../../interfaces/outil/controleCoherence/equilibreOperationOrdreTransfertFonctionnementData';

class EquilibreOperationOrdreTransfertFonctionnement extends BaseControleCoherence {
  data: EquilibreOperationOrdreTransfertFonctionnementData;

  famille = FamilleControleCoherence.OPERATIONS_ORDRE;

  constructor(data: EquilibreOperationOrdreTransfertFonctionnementData) {
    super(data);
    this.data = data;
  }

  getExplicatif(typeBudget: TypeBudget): string {
    const depenseFonctionnement042 = formatMontant(this.data.depenseFonctionnement042, 2, true);
    const recetteInvestissement040 = formatMontant(this.data.recetteInvestissement040, 2, true);

    switch (this.statut) {
      case StatutControleCoherence.ERROR:
        if (typeBudget === TypeBudget.COMPTE_ADMINISTRATIF) {
          return `Le total des dépenses de fonctionnement réalisées au chapitre 042 (${depenseFonctionnement042}) diffère des recettes d'investissement réalisées au 040 (${recetteInvestissement040}).`;
        }
        return `Le total des prévisions budgétaires inscrites en dépenses de fonctionnement au chapitre 042 (${depenseFonctionnement042}) diffère des recettes d'investissement inscrites au 040 (${recetteInvestissement040}).`;
      case StatutControleCoherence.OK:
        if (typeBudget === TypeBudget.COMPTE_ADMINISTRATIF) {
          return `Le total des dépenses de fonctionnement réalisées au chapitre 042 est égal aux recettes d'investissement réalisées au 040 (${recetteInvestissement040}).`;
        }
        return `Le total des prévisions budgétaires inscrites en dépenses de fonctionnement au chapitre 042 est égal aux recettes d'investissement inscrites au 040 (${recetteInvestissement040}).`;
      default:
        return '';
    }
  }

  getLibelle(): string {
    switch (this.statut) {
      case StatutControleCoherence.ERROR:
        return 'Déséquilibre des opérations d’ordre de transfert de la section de fonctionnement vers la section d\'investissement';
      case StatutControleCoherence.OK:
        return 'Équilibre des opérations d’ordre de transfert de la section de fonctionnement vers la section d\'investissement';
      default:
        return '';
    }
  }

  getStatut(data: EquilibreOperationOrdreTransfertFonctionnementData): StatutControleCoherence {
    const { depenseFonctionnement042, recetteInvestissement040 } = data;

    if (depenseFonctionnement042 === recetteInvestissement040) {
      return StatutControleCoherence.OK;
    }

    return StatutControleCoherence.ERROR;
  }
}

export default EquilibreOperationOrdreTransfertFonctionnement;
