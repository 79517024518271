import React from 'react';

import {
  type CheckboxProps,
  Combobox,
  Form,
  Grid,
} from '@jvs-group/jvs-mairistem-composants';

import CalculPropositionModalCheckboxInput from './CalculPropositionModalCheckboxInput';

import type { MajorationMinorationValues } from './CalculPropositionModal';
import TypeArrondiRegleCalcul from '../../../../constants/simulation/typeArrondiRegleCalcul';

interface CalculPropositionModalOptionsProps {
  majorationMinorationValues: MajorationMinorationValues;
  onChangeInput: (e: React.ChangeEvent<HTMLInputElement>, value: number) => void;
  onChangeTypeArrondi: (e: React.ChangeEvent<HTMLSelectElement>,
    { name, value }: { name: string, value: number }) => void;
  typeArrondi: TypeArrondiRegleCalcul;
}

const TYPE_ARRONDI_REGLE_CALCUL_OPTIONS = [
  {
    'data-testid': `typeArrondi${TypeArrondiRegleCalcul.AUCUN}`,
    key: TypeArrondiRegleCalcul.AUCUN,
    text: 'Sans arrondi',
    value: TypeArrondiRegleCalcul.AUCUN,
  },
  {
    'data-testid': `typeArrondi${TypeArrondiRegleCalcul.EURO}`,
    key: TypeArrondiRegleCalcul.EURO,
    text: "À l'euro",
    value: TypeArrondiRegleCalcul.EURO,
  },
  {
    'data-testid': `typeArrondi${TypeArrondiRegleCalcul.DIZAINE}`,
    key: TypeArrondiRegleCalcul.DIZAINE,
    text: "À la dizaine d'euro",
    value: TypeArrondiRegleCalcul.DIZAINE,
  },
  {
    'data-testid': `typeArrondi${TypeArrondiRegleCalcul.CENTAINE}`,
    key: TypeArrondiRegleCalcul.CENTAINE,
    text: "À la centaine d'euros",
    value: TypeArrondiRegleCalcul.CENTAINE,
  },
] as const;

const CalculPropositionModalOptions = ({
  majorationMinorationValues,
  onChangeInput,
  onChangeTypeArrondi,
  typeArrondi,
}: CalculPropositionModalOptionsProps) => {
  const [checkboxsChecked, setCheckboxChecked] = React.useState({ forfaitaire: false, pourcentage: false });

  const handleChangeCheckbox = (e: React.MouseEvent<HTMLLabelElement>, { checked, name }: CheckboxProps) => {
    setCheckboxChecked((old) => ({
      ...old,
      [name]: checked,
    }));

    // Remise à zéro de la valeur si unCheck
    if (!checked) onChangeInput({ currentTarget: { name } } as React.ChangeEvent<HTMLInputElement>, 0);
  };

  React.useEffect(() => {
    setCheckboxChecked({
      forfaitaire: majorationMinorationValues.forfaitaire !== 0,
      pourcentage: majorationMinorationValues.pourcentage !== 0,
    });
  }, [majorationMinorationValues]);

  return (
    <Grid>
      <Grid.Row columns={2}>
        <Grid.Column>
          <Form.Field
            checkbox={{
              checked: checkboxsChecked.pourcentage,
              label: 'exprimée en pourcentage',
              name: 'pourcentage',
              onChange: handleChangeCheckbox,
            }}
            control={CalculPropositionModalCheckboxInput}
            currency={{
              disabled: !checkboxsChecked.pourcentage,
              icon: 'percent',
              name: 'pourcentage',
              onBlur: onChangeInput,
              value: majorationMinorationValues?.pourcentage,
            }}
            label="Application d'une majoration / minoration"
            testid="majorationMinorationPourcentage"
          />
          <Form.Field
            checkbox={{
              checked: checkboxsChecked.forfaitaire,
              label: 'forfaitaire',
              name: 'forfaitaire',
              onChange: handleChangeCheckbox,
            }}
            control={CalculPropositionModalCheckboxInput}
            currency={{
              disabled: !checkboxsChecked.forfaitaire,
              icon: 'eur',
              name: 'forfaitaire',
              onBlur: onChangeInput,
              value: majorationMinorationValues?.forfaitaire,
            }}
            testid="majorationMinorationForfaitaire"
          />
        </Grid.Column>
        <Grid.Column>
          <Form.Field
            control={Combobox}
            data-testid="typeArrondiCombobox"
            label="Arrondi du résultat"
            menuPortalTarget={document.body}
            name="typeArrondi"
            onChange={onChangeTypeArrondi}
            options={TYPE_ARRONDI_REGLE_CALCUL_OPTIONS}
            selection
            value={typeArrondi}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default CalculPropositionModalOptions;
