import { formatMontant } from '@jvs-group/jvs-mairistem-finances-utils';
import BaseControleCoherence from './BaseControleCoherence';
import FamilleControleCoherence from '../../../constants/simulation/familleControleCoherence';
import StatutControleCoherence from '../../../constants/simulation/statutControleCoherence';
import type PlafondDepenseImprevuInvestissementData from '../../../interfaces/outil/controleCoherence/plafondDepenseImprevuInvestissementData';

class PlafondDepenseImprevuInvestissement extends BaseControleCoherence {
  data: PlafondDepenseImprevuInvestissementData;

  famille = FamilleControleCoherence.AUTRES;

  constructor(data: PlafondDepenseImprevuInvestissementData) {
    super(data);
    this.data = data;
  }

  getExplicatif(): string {
    const depenseInvestissement020 = formatMontant(this.data.depenseInvestissement020, 2, true);
    const { depenseInvestissementReelle } = this.data;

    switch (this.statut) {
      case StatutControleCoherence.ERROR:
        return `Les crédits inscrits au 020 (${depenseInvestissement020}) sont strictement supérieur aux 7,5% des dépenses réelles de investissement hors restes à réaliser (${depenseInvestissementReelle} x 7,5% = ${formatMontant(depenseInvestissementReelle * 0.075, 2, true)}).`;
      case StatutControleCoherence.OK:
        return `Les crédits inscrits au 020 (${depenseInvestissement020}) sont inférieurs aux 7,5% des dépenses réelles d'investissement hors restes à réaliser (${depenseInvestissementReelle} x 7,5% = ${formatMontant(depenseInvestissementReelle * 0.075, 2, true)}).`;
      default:
        return '';
    }
  }

  getLibelle(): string {
    switch (this.statut) {
      case StatutControleCoherence.ERROR:
        return 'Non-respect du plafond des dépenses imprévues en investissement';
      case StatutControleCoherence.OK:
        return 'Respect du plafond des dépenses imprévues en investissement';
      default:
        return '';
    }
  }

  getStatut(data: PlafondDepenseImprevuInvestissementData): StatutControleCoherence {
    const { depenseInvestissement020, depenseInvestissementReelle } = data;

    // DI_022 <= 7,5% de depenseInvestissementReelle
    if (depenseInvestissement020 <= (depenseInvestissementReelle * 0.075)) {
      return StatutControleCoherence.OK;
    }

    return StatutControleCoherence.ERROR;
  }
}

export default PlafondDepenseImprevuInvestissement;
