import React from 'react';
import BaseTypeRegroupement from './BaseTypeRegroupement';
import TypeRegroupement from '../../constants/feuilleSaisie/typeRegroupement';
import type TreeRow from '../../interfaces/feuilleSaisie/treeRow';
import type BaseFeuilleSaisie from '../feuilleSaisies/BaseFeuilleSaisie';

class OperationTypeRegroupement extends BaseTypeRegroupement {
  constructor() {
    super();
    this.code = TypeRegroupement.OPERATION;
    this.codeColumnName = 'ope_code';
    this.libelleColumnName = 'ope_libelle';
    this.icon = {
      iconSet: 'Lucide',
      name: 'Hammer',
    };
    this.libelle = 'Opération';
  }

  getLibelle(data: TreeRow, feuille: BaseFeuilleSaisie): string {
    if (data.ope_code === 'OPNI') {
      return 'Opération non-individualisée';
    }

    if (data.ope_code === 'OPFI') {
      return 'Opération financière';
    }

    return super.getLibelle(data, feuille);
  }

  getTabLibelle(data: TreeRow): React.JSX.Element {
    if (data.ope_code === 'OPNI') {
      return (
        <>
          <div>Opération</div>
          non-individualisée
        </>
      );
    }

    if (data.ope_code === 'OPFI') {
      return (
        <>
          <div>Opération</div>
          financière
        </>
      );
    }

    return super.getTabLibelle(data);
  }
}

export default OperationTypeRegroupement;
