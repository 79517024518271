import { formatMontant } from '@jvs-group/jvs-mairistem-finances-utils';
import BaseControleCoherence from './BaseControleCoherence';
import FamilleControleCoherence from '../../../constants/simulation/familleControleCoherence';
import StatutControleCoherence from '../../../constants/simulation/statutControleCoherence';
import type EquilibreVirementSectionFonctionnementData from '../../../interfaces/outil/controleCoherence/equilibreVirementSectionFonctionnementData';

class EquilibreVirementSectionFonctionnement extends BaseControleCoherence {
  data: EquilibreVirementSectionFonctionnementData;

  famille = FamilleControleCoherence.OPERATIONS_ORDRE;

  constructor(data: EquilibreVirementSectionFonctionnementData) {
    super(data);
    this.data = data;
  }

  getExplicatif(): string {
    const depenseFonctionnement023 = formatMontant(this.data.depenseFonctionnement023, 2, true);
    const recetteInvestissement021 = formatMontant(this.data.recetteInvestissement021, 2, true);

    switch (this.statut) {
      case StatutControleCoherence.ERROR:
        return `Le total des prévisions budgétaires inscrites en dépenses de fonctionnement au chapitre-article 023 (${depenseFonctionnement023}) diffère des recettes d'investissement inscrites au 021 (${recetteInvestissement021}).`;
      case StatutControleCoherence.OK:
        return `Le total des prévisions budgétaires inscrites en dépenses de fonctionnement au chapitre-article 023 est égal aux recettes d'investissement inscrites au 021 (${recetteInvestissement021}).`;
      default:
        return '';
    }
  }

  getLibelle(): string {
    switch (this.statut) {
      case StatutControleCoherence.ERROR:
        return 'Déséquilibre du virement de la section de fonctionnement à la section d’investissement';
      case StatutControleCoherence.OK:
        return 'Équilibre du virement de la section de fonctionnement à la section d’investissement';
      default:
        return '';
    }
  }

  getStatut(data: EquilibreVirementSectionFonctionnementData): StatutControleCoherence {
    const { depenseFonctionnement023, recetteInvestissement021 } = data;

    if (depenseFonctionnement023 === recetteInvestissement021) {
      return StatutControleCoherence.OK;
    }

    return StatutControleCoherence.ERROR;
  }
}

export default EquilibreVirementSectionFonctionnement;
