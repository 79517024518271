// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import React from 'react';
import { StoreProvider } from '@jvs-group/jvs-mairistem-store';

import { Router, Route } from 'react-router-dom';

import { ToastContainer } from 'react-toastify';
import type { History } from 'history';
import { createPortal } from 'react-dom';
import { getUserRole } from '../../../utils/utilisateur';
import SessionContext from './SessionContext';
import UserRole from '../../../constants/common/userRole';

interface ContextProps {
  children: string | React.FunctionComponent<any> | React.ComponentClass<any, any>;
  history: History;
  path: string;
  scope: string;
}

const Context = ({
  children,
  history,
  path,
  scope,
}: ContextProps) => {
  const [userRole, setUserRole] = React.useState<UserRole>(UserRole.NORMAL);

  const sessionContextValue = React.useMemo(() => ({ userRole }), [userRole]);

  const fetchUserRole = async () => setUserRole(await getUserRole());

  React.useEffect(() => {
    fetchUserRole();
  }, []);

  return (
    <StoreProvider>
      { scope === 'content' && createPortal(<ToastContainer />, document.getElementById('root')) }

      <Router history={history}>
        <Route path={path}>
          <SessionContext.Provider value={sessionContextValue}>
            {
              React.isValidElement(children)
                ? children
                : React.createElement(children)
            }
          </SessionContext.Provider>
        </Route>
      </Router>
    </StoreProvider>
  );
};

export default Context;
