import FamilleControleCoherence from '../../../constants/simulation/familleControleCoherence';
import StatutControleCoherence from '../../../constants/simulation/statutControleCoherence';
import type TypeBudget from '../../../constants/simulation/typeBudget';
import type ControleCoherenceItemData from '../../../interfaces/outil/controleCoherence/controleCoherenceItemData';

class BaseControleCoherence {
  famille: FamilleControleCoherence;

  statut: StatutControleCoherence;

  constructor(data: ControleCoherenceItemData) {
    this.statut = this.getStatut(data);
  }

  getExplicatif(_typeBudget: TypeBudget): string { return ''; }

  getFamille(): string {
    switch (this.famille) {
      case FamilleControleCoherence.EQUILIBRE_BUDGETAIRE:
        return 'Equilibre budgétaire';
      case FamilleControleCoherence.OPERATIONS_ORDRE:
        return "Opérations d'ordre";
      case FamilleControleCoherence.AUTRES:
        return 'Autres règles';
      default:
        return '';
    }
  }

  getLibelle(): string { return ''; }

  getLibelleWithStatut(): string {
    let prefix = '';

    switch (this.statut) {
      case StatutControleCoherence.ERROR:
        prefix = 'Anomalie';
        break;
      case StatutControleCoherence.WARNING:
        prefix = 'A vérifier';
        break;
      case StatutControleCoherence.OK:
        prefix = 'Contrôle OK';
        break;
      default:
        break;
    }

    return `${prefix} : ${this.getLibelle()}`;
  }

  getStatut(_data: ControleCoherenceItemData): StatutControleCoherence { return StatutControleCoherence.ERROR; }
}

export default BaseControleCoherence;
