import React from 'react';
import { ListeTable } from '@jvs-group/jvs-mairistem-liste';
import './RegleCalculListRow.less';
import type RegleCalcul from '../../../../interfaces/simulation/regleCalcul';

interface RegleCalculListRowProps {
  regleCalcul: RegleCalcul;
  rowProps: any,
}

const RegleCalculListRow = ({
  regleCalcul,
  rowProps,
}: RegleCalculListRowProps) => (
  <ListeTable.Row key={regleCalcul.identifiant} {...rowProps}>
    <ListeTable.Cell name="libelle" verticalAlign="middle">
      {regleCalcul.libelle}
    </ListeTable.Cell>
  </ListeTable.Row>
);

export default RegleCalculListRow;
