import FeuilleSaisieCode from '../../constants/feuilleSaisie/feuilleSaisieCode';
import TypeRegroupement from '../../constants/feuilleSaisie/typeRegroupement';
import AnalytiqueTypeRegroupement from '../typeRegroupement/AnalytiqueTypeRegroupement';
import ArticleTypeRegroupement from '../typeRegroupement/ArticleTypeRegroupement';
import ChapitreTypeRegroupement from '../typeRegroupement/ChapitreTypeRegroupement';
import FonctionTypeRegroupement from '../typeRegroupement/FonctionTypeRegroupement';
import ImputationTypeRegroupement from '../typeRegroupement/ImputationTypeRegroupement';
import OperationTypeRegroupement from '../typeRegroupement/OperationTypeRegroupement';
import SensTypeRegroupement from '../typeRegroupement/SensTypeRegroupement';
import VentilationTypeRegroupement from '../typeRegroupement/VentilationTypeRegroupement';
import BaseFeuilleSaisie from './BaseFeuilleSaisie';

class InvestissementOperationFeuilleSaisie extends BaseFeuilleSaisie {
  constructor() {
    super();
    this.chapitreArticleLie = true;
    this.code = FeuilleSaisieCode.INVESTISSEMENT_PAR_OPERATION;
    this.isCreditReporteShown = true;
    this.section = 'I';
    this.libelle = 'Investissement (par opération)';
    this.defaultCodeFinArbo = TypeRegroupement.CHAPITRE;
    this.nodes = [
      [new OperationTypeRegroupement()],
      [new SensTypeRegroupement()],
      [new ChapitreTypeRegroupement()],
      [new ArticleTypeRegroupement()],
      [new FonctionTypeRegroupement()],
      [new AnalytiqueTypeRegroupement()],
      [new VentilationTypeRegroupement(), new ImputationTypeRegroupement()],
    ];

    this.icon = {
      iconSet: 'Lucide',
      name: 'Building',
    };
  }
}

export default InvestissementOperationFeuilleSaisie;
