import React from 'react';
import { Grid } from '@jvs-group/jvs-mairistem-composants';
import { formatMontant } from '@jvs-group/jvs-mairistem-finances-utils';
import { getSoldeClass } from '../../../utils/simulation/panel';
import type PanelProps from '../../../interfaces/simulation/panelProps';

const BalanceInvestissement = ({ data }: PanelProps) => {
  const solde = (data?.recetteInvestissement ?? 0) - (data?.depenseInvestissement ?? 0);
  const soldeClass = getSoldeClass(solde);

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column className="bold indicateurTitle">Investissement</Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={10}>
          <div>Dépense</div>
          <div>Recette</div>
        </Grid.Column>
        <Grid.Column width={6} textAlign="right">
          <div>{formatMontant(data?.depenseInvestissement ?? 0)}</div>
          <div>{formatMontant(data?.recetteInvestissement ?? 0)}</div>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row className={soldeClass?.join(' ')}>
        <Grid.Column width={10}> Solde </Grid.Column>
        <Grid.Column width={6} textAlign="right">
          {formatMontant(solde)}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default BalanceInvestissement;
