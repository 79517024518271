import React from 'react';
import { formatMontant } from '@jvs-group/jvs-mairistem-finances-utils';
import type { MajorationMinorationValues } from './CalculPropositionModal';
import type DetailCalcul from '../../../../interfaces/simulation/detailCalcul';
import type RegleCalcul from '../../../../interfaces/simulation/regleCalcul';
import REGLE_CALCUL_ALIAS from '../../../../constants/simulation/regleCalcul';
import { TypeCalculRegleCalcul } from '../../../../constants/simulation/typeCalculRegleCalcul';
import formatMontantWithOperator from '../../../../utils/simulation/calcul';

interface CalculPropositionModalOperationProps {
  anneeExercice: number;
  majorationMinorationValues: MajorationMinorationValues;
  detailCalcul: DetailCalcul;
  regleCalcul: RegleCalcul;
}

const CalculPropositionModalOperation = ({
  anneeExercice,
  majorationMinorationValues,
  detailCalcul,
  regleCalcul,
}: CalculPropositionModalOperationProps) => {
  const getRegleLinesByOperande = (
    soustraction: boolean,
  ) => regleCalcul?.lignes?.filter((ligne) => ligne?.minus === soustraction);

  const renderMinusEquation = () => {
    const minusLines = getRegleLinesByOperande(true);
    if (minusLines?.length === 0) {
      return null;
    }

    return (
      <>
        {' -'}
        {
        getRegleLinesByOperande(true)?.map((ligne) => formatMontant(
          detailCalcul?.[REGLE_CALCUL_ALIAS[ligne?.typeMontant]] ?? 0,
        ))?.join(' + ')
      }
      </>
    );
  };

  return (
    <div className="calculPropositionOperation">
      {`Proposition ${anneeExercice} `}
      {' '}
      :
      {' '}
      <span className={
          regleCalcul?.typeCalcul === TypeCalculRegleCalcul.MOYENNE ? 'calculPropositionOperationMoyenne' : ''
        }
      >
        <span>
          {getRegleLinesByOperande(false)?.map((ligne) => formatMontant(
            detailCalcul?.[REGLE_CALCUL_ALIAS[ligne?.typeMontant]] ?? 0,
          ))?.join(' + ')}
          {renderMinusEquation()}
        </span>

        {regleCalcul?.typeCalcul === TypeCalculRegleCalcul.MOYENNE && (
        <span className="denominator">{regleCalcul?.lignes?.length}</span>
        )}
      </span>
      {' '}
      {majorationMinorationValues?.pourcentage !== 0
      && `${formatMontantWithOperator(majorationMinorationValues?.pourcentage, true)} `}
      {majorationMinorationValues?.forfaitaire !== 0
      && `${formatMontantWithOperator(majorationMinorationValues?.forfaitaire)}`}
      {` = ${formatMontant(detailCalcul?.montant ?? 0, 2, true)}`}
    </div>
  );
};

export default CalculPropositionModalOperation;
