import type { Exercice } from '@jvs-group/jvs-mairistem-comptabilite';
import type BaseFeuilleSaisie from './BaseFeuilleSaisie';
import DotationsAuxAmortissementFeuilleSaisie from './DotationsAuxAmortissementFeuilleSaisie';
import FonctionnementFeuilleSaisie from './FonctionnementFeuilleSaisie';
import InvestissementChapitreFeuilleSaisie from './InvestissementChapitreFeuilleSaisie';
import InvestissementOperationFeuilleSaisie from './InvestissementOperationFeuilleSaisie';
import OperationOrdreBudgetaireFeuilleSaisie from './OperationOrdreBudgetaireFeuilleSaisie';
import FeuilleSaisieCode from '../../constants/feuilleSaisie/feuilleSaisieCode';
import type FeuilleSaisie from '../../interfaces/feuilleSaisie/feuilleSaisie';

class FeuilleSaisieFactory {
  static newInstance(feuille: FeuilleSaisie, exercice: Exercice): BaseFeuilleSaisie {
    let feuilleSaisie: BaseFeuilleSaisie = null;
    switch (feuille.code) {
      case FeuilleSaisieCode.DOTATIONS_AUX_AMORTISSEMENT:
        feuilleSaisie = new DotationsAuxAmortissementFeuilleSaisie();
        break;
      case FeuilleSaisieCode.FONCTIONNEMENT:
        feuilleSaisie = new FonctionnementFeuilleSaisie();
        break;
      case FeuilleSaisieCode.INVESTISSEMENT_PAR_CHAPITRE:
        feuilleSaisie = new InvestissementChapitreFeuilleSaisie();
        break;
      case FeuilleSaisieCode.INVESTISSEMENT_PAR_OPERATION:
        feuilleSaisie = new InvestissementOperationFeuilleSaisie();
        break;
      case FeuilleSaisieCode.OPERATION_ORDRE_BUDGETAIRE:
        feuilleSaisie = new OperationOrdreBudgetaireFeuilleSaisie();
        break;
      default:
        break;
    }

    feuilleSaisie.setNoeuds(feuille, exercice);
    feuilleSaisie.chapitreArticleLie = feuille.chapitreArticleLie;
    return feuilleSaisie;
  }
}

export default FeuilleSaisieFactory;
