import TypeRegroupement from '../../constants/feuilleSaisie/typeRegroupement';
import BaseTypeRegroupement from './BaseTypeRegroupement';

class AnalytiqueTypeRegroupement extends BaseTypeRegroupement {
  constructor() {
    super();
    this.code = TypeRegroupement.ANALYTIQUE;
    this.codeColumnName = 'ana_code';
    this.icon = {
      iconSet: 'Lucide',
      name: 'LifeBuoy',
    };
    this.libelleColumnName = 'ana_libelle';
    this.libelle = 'Analytique';
  }
}

export default AnalytiqueTypeRegroupement;
