import type BaseFeuilleSaisie from '../../classes/feuilleSaisies/BaseFeuilleSaisie';
import DotationsAuxAmortissementFeuilleSaisie
  from '../../classes/feuilleSaisies/DotationsAuxAmortissementFeuilleSaisie';
import FonctionnementFeuilleSaisie from '../../classes/feuilleSaisies/FonctionnementFeuilleSaisie';
import InvestissementChapitreFeuilleSaisie from '../../classes/feuilleSaisies/InvestissementChapitreFeuilleSaisie';
import InvestissementOperationFeuilleSaisie from '../../classes/feuilleSaisies/InvestissementOperationFeuilleSaisie';
import OperationOrdreBudgetaireFeuilleSaisie from '../../classes/feuilleSaisies/OperationOrdreBudgetaireFeuilleSaisie';

const FEUILLES_SAISIES: BaseFeuilleSaisie[] = [
  new DotationsAuxAmortissementFeuilleSaisie(),
  new FonctionnementFeuilleSaisie(),
  new InvestissementChapitreFeuilleSaisie(),
  new InvestissementOperationFeuilleSaisie(),
  new OperationOrdreBudgetaireFeuilleSaisie(),
];

export default FEUILLES_SAISIES;
