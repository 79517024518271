export enum TypeCalculRegleCalcul {
  AUCUN = 0,
  ADDITION = 1,
  SOUSTRACTION = 2,
  MOYENNE = 3,
}

export enum TypeCalculRegleCalculText {
  'Aucun',
  'Addition',
  'Soustraction',
  'Moyenne',
}
