import ChapitreSansExecutionBudgetaire from './ChapitreSansExecutionBudgetaire';
import EquilibreOperationOrdreInterieurSectionFonctionnement
  from './EquilibreOperationOrdreInterieurSectionFonctionnement';
import EquilibreOperationOrdrePatrimoniale from './EquilibreOperationOrdrePatrimoniale';
import EquilibreOperationOrdreTransfertFonctionnement from './EquilibreOperationOrdreTransfertFonctionnement';
import EquilibreOperationOrdreTransfertInvestissement from './EquilibreOperationOrdreTransfertInvestissement';
import EquilibreReel from './EquilibreReel';
import EquilibreSectionFonctionnement from './EquilibreSectionFonctionnement';
import EquilibreSectionInvestissement from './EquilibreSectionInvestissement';
import EquilibreVirementSectionFonctionnement from './EquilibreVirementSectionFonctionnement';
import OperationCession from './OperationCession';
import PlafondDepenseImprevuFonctionnement from './PlafondDepenseImprevuFonctionnement';
import PlafondDepenseImprevuInvestissement from './PlafondDepenseImprevuInvestissement';
import type BaseControleCoherence from './BaseControleCoherence';
import TypeControleCoherence from '../../../constants/simulation/typeControleCoherence';
import PrevisionCaduque from './PrevisionCaduque';
import RealisationCaduque from './RealisationCaduque';
import type { ControleCoherence } from '../../../interfaces/outil/controleCoherence/controleCoherence';

class ControleCoherenceFactory {
  // Permet de creer une liste d'instances
  static newInstances(data: ControleCoherence): BaseControleCoherence[] {
    const controles: BaseControleCoherence[] = [];

    Object.entries(data).forEach(([key, value]: [TypeControleCoherence, any]) => {
      switch (key) {
        case TypeControleCoherence.CHAPITRE_SANS_EXECUTION_BUDGETAIRE:
          controles.push(new ChapitreSansExecutionBudgetaire(value));
          break;
        case TypeControleCoherence.OPERATIONS_CESSION:
          controles.push(new OperationCession(value));
          break;
        case TypeControleCoherence.OPERATIONS_ORDRE_INTERIEUR_SECTION_FONCTIONNEMENT:
          controles.push(new EquilibreOperationOrdreInterieurSectionFonctionnement(value));
          break;
        case TypeControleCoherence.OPERATIONS_ORDRE_PATRIMONIALES:
          controles.push(new EquilibreOperationOrdrePatrimoniale(value));
          break;
        case TypeControleCoherence.OPERATIONS_ORDRE_TRANSFERT_SECTION_FONCTIONNEMENT:
          controles.push(new EquilibreOperationOrdreTransfertFonctionnement(value));
          break;
        case TypeControleCoherence.OPERATIONS_ORDRE_TRANSFERT_SECTION_INVESTISSEMENT:
          controles.push(new EquilibreOperationOrdreTransfertInvestissement(value));
          break;
        case TypeControleCoherence.PLAFOND_DEPENSES_IMPREVUES_FONCTIONNEMENT:
          controles.push(new PlafondDepenseImprevuFonctionnement(value));
          break;
        case TypeControleCoherence.PLAFOND_DEPENSES_IMPREVUES_INVESTISSEMENT:
          controles.push(new PlafondDepenseImprevuInvestissement(value));
          break;
        case TypeControleCoherence.PREVISION_CADUQUE:
          controles.push(new PrevisionCaduque(value));
          break;
        case TypeControleCoherence.REALISATION_CADUQUE:
          controles.push(new RealisationCaduque(value));
          break;
        case TypeControleCoherence.REEL:
          controles.push(new EquilibreReel(value));
          break;
        case TypeControleCoherence.SECTION_FONCTIONNEMENT:
          controles.push(new EquilibreSectionFonctionnement(value));
          break;
        case TypeControleCoherence.SECTION_INVESTISSEMENT:
          controles.push(new EquilibreSectionInvestissement(value));
          break;
        case TypeControleCoherence.VIREMENT_SECTION_FONCTIONNEMENT:
          controles.push(new EquilibreVirementSectionFonctionnement(value));
          break;
        default:
          break;
      }
    });

    return controles;
  }
}

export default ControleCoherenceFactory;
