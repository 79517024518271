import { formatMontant } from '@jvs-group/jvs-mairistem-finances-utils';
import BaseControleCoherence from './BaseControleCoherence';
import FamilleControleCoherence from '../../../constants/simulation/familleControleCoherence';
import TypeBudget from '../../../constants/simulation/typeBudget';
import StatutControleCoherence from '../../../constants/simulation/statutControleCoherence';
import type EquilibreOperationOrdrePatrimonialeData from '../../../interfaces/outil/controleCoherence/equilibreOperationOrdrePatrimonialeData';

class EquilibreOperationOrdrePatrimoniale extends BaseControleCoherence {
  data: EquilibreOperationOrdrePatrimonialeData;

  famille = FamilleControleCoherence.OPERATIONS_ORDRE;

  constructor(data: EquilibreOperationOrdrePatrimonialeData) {
    super(data);
    this.data = data;
  }

  getExplicatif(typeBudget: TypeBudget): string {
    const depenseInvestissement041 = formatMontant(this.data.depenseInvestissement041, 2, true);
    const recetteInvestissement041 = formatMontant(this.data.recetteInvestissement041, 2, true);

    switch (this.statut) {
      case StatutControleCoherence.ERROR:
        if (typeBudget === TypeBudget.COMPTE_ADMINISTRATIF) {
          return `Le total des dépenses d'investissement réalisées au chapitre 041 (${depenseInvestissement041}) diffère des recettes d'investissement réalisées au 041 (${recetteInvestissement041}).`;
        }
        return `Le total des prévisions budgétaires inscrites en dépenses d'investissement au chapitre 041 (${depenseInvestissement041}) diffère des recettes d'investissement inscrites au 041 (${recetteInvestissement041}).`;
      case StatutControleCoherence.OK:
        if (typeBudget === TypeBudget.COMPTE_ADMINISTRATIF) {
          return `Le total des dépenses d'investissement réalisées au chapitre 041 est égal aux recettes d'investissement réalisées au 041 (${depenseInvestissement041}).`;
        }
        return `Le total des prévisions budgétaires inscrites en dépenses d'investissement au chapitre 041 est égal aux recettes d'investissement inscrites au 041 (${depenseInvestissement041}).`;
      default:
        return '';
    }
  }

  getLibelle(): string {
    switch (this.statut) {
      case StatutControleCoherence.ERROR:
        return 'Déséquilibre des opérations d’ordre patrimoniales';
      case StatutControleCoherence.OK:
        return 'Équilibre des opérations d’ordre patrimoniales';
      default:
        return '';
    }
  }

  getStatut(data: EquilibreOperationOrdrePatrimonialeData): StatutControleCoherence {
    const { depenseInvestissement041, recetteInvestissement041 } = data;

    if (depenseInvestissement041 === recetteInvestissement041) {
      return StatutControleCoherence.OK;
    }

    return StatutControleCoherence.ERROR;
  }
}

export default EquilibreOperationOrdrePatrimoniale;
