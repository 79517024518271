import React from 'react';
import { Grid } from '@jvs-group/jvs-mairistem-composants';
import { chunk } from 'lodash';
import { Icon } from '@jvs-group/jvs-mairistem-finances-utils';
import type BaseFeuilleSaisie from '../../classes/feuilleSaisies/BaseFeuilleSaisie';
import type Simulation from '../../interfaces/simulation/simulation';
import FEUILLES_SAISIES from '../../constants/feuilleSaisie/feuillesSaisies';

interface TreeViewFeuilleSaisieProps {
  feuillesSaisies: BaseFeuilleSaisie[];
  simulation: Simulation;
  onSelect: (feuille: BaseFeuilleSaisie) => void;
}

const FeuilleSaisiePopupList = ({
  feuillesSaisies,
  simulation,
  onSelect,
}: TreeViewFeuilleSaisieProps) => {
  /**
    * J'affiche la différence entre les feuilles de saisie de l'utilisateur et ceux disponibles
    */
  const renderFeuilles = () => {
    // On fait une intersection entre toutes les feuilles possibles et ceux déjà présents
    const filteredFeuilles = FEUILLES_SAISIES.filter(
      (feuille) => !feuillesSaisies.some((f) => f?.code === feuille?.code) && feuille.isVisible(simulation),
    );

    // On fait des groupes de 4 panels
    const feuilleRows = chunk(filteredFeuilles, 4);

    return (
      feuilleRows.map((row) => (
        <Grid.Row>
          { row.map((feuille) => (
            <Grid.Column
              computer={4}
              largeScreen={4}
              mobile={6}
              textAlign="center"
              verticalAlign="middle"
              key={feuille?.code}
              data-code={feuille?.code}
              className="feuilleListItem"
              onClick={() => onSelect(feuille)}
            >
              <Icon
                size="big"
                {...feuille.icon}
              />
              <div>{feuille?.libelle}</div>
            </Grid.Column>
          ))}
        </Grid.Row>
      ))
    );
  };

  return (
    <Grid className="feuilleListGrid">
      { renderFeuilles() }
    </Grid>
  );
};

export default FeuilleSaisiePopupList;
