import FeuilleSaisieCode from '../../constants/feuilleSaisie/feuilleSaisieCode';
import TypeRegroupement from '../../constants/feuilleSaisie/typeRegroupement';
import AnalytiqueTypeRegroupement from '../typeRegroupement/AnalytiqueTypeRegroupement';
import ArticleTypeRegroupement from '../typeRegroupement/ArticleTypeRegroupement';
import ChapitreTypeRegroupement from '../typeRegroupement/ChapitreTypeRegroupement';
import FonctionTypeRegroupement from '../typeRegroupement/FonctionTypeRegroupement';
import ImputationTypeRegroupement from '../typeRegroupement/ImputationTypeRegroupement';
import SectionTypeRegroupement from '../typeRegroupement/SectionTypeRegroupement';
import SensTypeRegroupement from '../typeRegroupement/SensTypeRegroupement';
import VentilationTypeRegroupement from '../typeRegroupement/VentilationTypeRegroupement';
import BaseFeuilleSaisie from './BaseFeuilleSaisie';

class DotationsAuxAmortissementFeuilleSaisie extends BaseFeuilleSaisie {
  constructor() {
    super();
    this.chapitreArticleLie = true;
    this.code = FeuilleSaisieCode.DOTATIONS_AUX_AMORTISSEMENT;
    this.libelle = 'Dotations aux amortissements';
    this.defaultCodeFinArbo = TypeRegroupement.CHAPITRE;
    this.nodes = [
      [new SectionTypeRegroupement()],
      [new SensTypeRegroupement()],
      [new ChapitreTypeRegroupement()],
      [new ArticleTypeRegroupement()],
      [new FonctionTypeRegroupement()],
      [new AnalytiqueTypeRegroupement()],
      [new VentilationTypeRegroupement(), new ImputationTypeRegroupement()],
    ];

    this.icon = {
      iconSet: 'Lucide',
      name: 'Car',
    };
  }
}

export default DotationsAuxAmortissementFeuilleSaisie;
