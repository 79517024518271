import { formatMontant } from '@jvs-group/jvs-mairistem-finances-utils';
import BaseControleCoherence from './BaseControleCoherence';
import FamilleControleCoherence from '../../../constants/simulation/familleControleCoherence';
import StatutControleCoherence from '../../../constants/simulation/statutControleCoherence';
import type EquilibreSectionInvestissementData from '../../../interfaces/outil/controleCoherence/equilibreSectionInvestissementData';

class EquilibreSectionInvestissement extends BaseControleCoherence {
  data: EquilibreSectionInvestissementData;

  famille = FamilleControleCoherence.EQUILIBRE_BUDGETAIRE;

  constructor(data: EquilibreSectionInvestissementData) {
    super(data);
    this.data = data;
  }

  getExplicatif(): string {
    const depenseInvestissement = formatMontant(this.data.depenseInvestissement, 2, true);
    const recetteInvestissement = formatMontant(this.data.recetteInvestissement, 2, true);

    switch (this.statut) {
      case StatutControleCoherence.ERROR:
        return `Le total des prévisions inscrites en dépenses d'investissement (${depenseInvestissement}) est différent des recettes d'investissement (${recetteInvestissement}).`;
      case StatutControleCoherence.OK:
        return `Le total des prévisions budgétaires inscrites en dépenses d'investissement est égal aux recettes d'investissement (${recetteInvestissement}).`;
      default:
        return '';
    }
  }

  getLibelle(): string {
    switch (this.statut) {
      case StatutControleCoherence.ERROR:
        return "Déséquilibre de la section d'investissement";
      case StatutControleCoherence.OK:
        return "Équilibre de la section d'investissement";
      default:
        return '';
    }
  }

  getStatut(data: EquilibreSectionInvestissementData): StatutControleCoherence {
    const { depenseInvestissement, recetteInvestissement } = data;

    if (depenseInvestissement === recetteInvestissement) {
      return StatutControleCoherence.OK;
    }

    return StatutControleCoherence.ERROR;
  }
}

export default EquilibreSectionInvestissement;
