import { getLibelleImputation } from '@jvs-group/jvs-mairistem-finances-utils';
import BaseControleCoherence from './BaseControleCoherence';
import FamilleControleCoherence from '../../../constants/simulation/familleControleCoherence';
import StatutControleCoherence from '../../../constants/simulation/statutControleCoherence';
import type PrevisionRealisationCaduqueData from '../../../interfaces/outil/controleCoherence/previsionRealisationCaduqueData';

class RealisationCaduque extends BaseControleCoherence {
  data: PrevisionRealisationCaduqueData[];

  famille = FamilleControleCoherence.AUTRES;

  constructor(data: PrevisionRealisationCaduqueData[]) {
    super(data);
    this.data = data;
  }

  getExplicatif(): string {
    switch (this.statut) {
      case StatutControleCoherence.ERROR: {
        const explicatifs = ['Les lignes suivantes comportent des réalisations sur l’exercice courant (ou N-1) malgré leur caducité:'];

        this.data.forEach((imputation) => {
          explicatifs.push(getLibelleImputation(
            imputation.imp_sens,
            imputation.cha_section,
            imputation.cha_code,
            imputation.art_code,
            imputation?.ope_code,
            imputation?.fon_code,
            imputation?.ven_code,
            imputation?.ana_code,
          ));
        });

        return explicatifs.join('\n');
      }
      case StatutControleCoherence.OK:
        return "Aucune ligne budgétaire caduque ne comporte de réalisation sur l'exercice courant.";
      default:
        return '';
    }
  }

  getLibelle(): string {
    return 'Lignes budgétaires caduques';
  }

  getStatut(data: PrevisionRealisationCaduqueData[]): StatutControleCoherence {
    return data.length > 0 ? StatutControleCoherence.ERROR : StatutControleCoherence.OK;
  }
}

export default RealisationCaduque;
