import React from 'react';
import { Header, Table } from '@jvs-group/jvs-mairistem-composants';
import { formatMontant, getLibelleImputation } from '@jvs-group/jvs-mairistem-finances-utils';
import type DetailCalcul from '../../../../interfaces/simulation/detailCalcul';
import type RegleCalcul from '../../../../interfaces/simulation/regleCalcul';
import getTypeMontantText from '../../../../utils/simulation/regleCalcul';
import REGLE_CALCUL_ALIAS from '../../../../constants/simulation/regleCalcul';

interface CalculPropositionModalTableProps {
  anneeExercice: number;
  detailCalcul: DetailCalcul;
  regleCalcul: RegleCalcul;
}

const CalculPropositionModalTable = ({
  anneeExercice,
  detailCalcul,
  regleCalcul,
}: CalculPropositionModalTableProps) => (
  <Table celled>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell content="Ligne budgétaire" />
        {regleCalcul?.lignes?.map((ligne) => (
          <Table.HeaderCell
            key={ligne?.identifiant}
            content={getTypeMontantText(ligne?.typeMontant, anneeExercice)}
            textAlign="right"
          />
        ))}

        <Table.HeaderCell
          content={`Proposition ${anneeExercice} calculée`}
          textAlign="right"
        />
      </Table.Row>
    </Table.Header>
    <Table.Body>
      <Table.Row>
        <Table.Cell>
          {getLibelleImputation(
            detailCalcul?.imp_sens,
            detailCalcul?.cha_section,
            detailCalcul?.cha_code,
            detailCalcul?.art_code,
            detailCalcul?.ope_code,
            detailCalcul?.fon_code,
            detailCalcul?.ven_code,
            detailCalcul?.ana_code,
          )}
        </Table.Cell>
        {regleCalcul?.lignes?.map((ligne) => (
          <Table.Cell
            key={ligne?.identifiant}
            content={formatMontant(detailCalcul?.[REGLE_CALCUL_ALIAS[ligne?.typeMontant]] ?? 0)}
            textAlign="right"
          />
        ))}

        <Table.Cell textAlign="right">
          <Header size="small">{formatMontant(detailCalcul?.montant ?? 0)}</Header>
        </Table.Cell>
      </Table.Row>
    </Table.Body>
  </Table>
);

export default CalculPropositionModalTable;
