export const formatWithSpaces = (value: number) => new Intl.NumberFormat('fr-FR', {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
}).format(value);

export const getSoldeClass = (solde: number) => {
  const soldeClass = ['solde'];
  if (solde > 0) {
    soldeClass.push('positive');
  } else if (solde < 0) {
    soldeClass.push('negative');
  }
  return soldeClass;
};
