import TYPE_MONTANT_REGLE_CALCUL from './typeMontantRegleCalcul';

const REGLE_CALCUL_ALIAS = {
  [TYPE_MONTANT_REGLE_CALCUL.BUDGET_TOTAL]: 'budget_total',
  [TYPE_MONTANT_REGLE_CALCUL.BUDGET_TOTAL_N1]: 'budget_total_n1',
  [TYPE_MONTANT_REGLE_CALCUL.BUDGET_TOTAL_N2]: 'budget_total_n2',
  [TYPE_MONTANT_REGLE_CALCUL.BUDGET_PRIMITIF]: 'budget_primitif',
  [TYPE_MONTANT_REGLE_CALCUL.BUDGET_PRIMITIF_N1]: 'budget_primitif_n1',
  [TYPE_MONTANT_REGLE_CALCUL.BUDGET_PRIMITIF_N2]: 'budget_primitif_n2',
  [TYPE_MONTANT_REGLE_CALCUL.BUDGET_SUPPLEMENTAIRE]: 'budget_supplementaire',
  [TYPE_MONTANT_REGLE_CALCUL.BUDGET_SUPPLEMENTAIRE_N1]: 'budget_supplementaire_n1',
  [TYPE_MONTANT_REGLE_CALCUL.BUDGET_SUPPLEMENTAIRE_N2]: 'budget_supplementaire_n2',
  [TYPE_MONTANT_REGLE_CALCUL.RESTES_A_REALISER_N1]: 'restes_a_realiser_n1',
  [TYPE_MONTANT_REGLE_CALCUL.DECISIONS_MODIFICATIVES]: 'decisions_modificatives',
  [TYPE_MONTANT_REGLE_CALCUL.DECISIONS_MODIFICATIVES_N1]: 'decisions_modificatives_n1',
  [TYPE_MONTANT_REGLE_CALCUL.DECISIONS_MODIFICATIVES_N2]: 'decisions_modificatives_n2',
  [TYPE_MONTANT_REGLE_CALCUL.REALISE]: 'realise_n',
  [TYPE_MONTANT_REGLE_CALCUL.REALISE_N1]: 'realise_n1',
  [TYPE_MONTANT_REGLE_CALCUL.REALISE_N2]: 'realise_n2',
} as const;

export default REGLE_CALCUL_ALIAS;
