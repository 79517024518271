import { formatMontant } from '@jvs-group/jvs-mairistem-finances-utils';
import BaseControleCoherence from './BaseControleCoherence';
import FamilleControleCoherence from '../../../constants/simulation/familleControleCoherence';
import StatutControleCoherence from '../../../constants/simulation/statutControleCoherence';
import type EquilibreSectionFonctionnementData from '../../../interfaces/outil/controleCoherence/equilibreSectionFonctionnementData';

class EquilibreSectionFonctionnement extends BaseControleCoherence {
  data: EquilibreSectionFonctionnementData;

  famille = FamilleControleCoherence.EQUILIBRE_BUDGETAIRE;

  constructor(data: EquilibreSectionFonctionnementData) {
    super(data);
    this.data = data;
  }

  getExplicatif(): string {
    const depenseFonctionnement = formatMontant(this.data.depenseFonctionnement, 2, true);
    const recetteFonctionnement = formatMontant(this.data.recetteFonctionnement, 2, true);

    switch (this.statut) {
      case StatutControleCoherence.ERROR:
        return `Le total des prévisions inscrites en dépenses de fonctionnement (${depenseFonctionnement}) est supérieur aux recettes de fonctionnement (${recetteFonctionnement}).`;
      case StatutControleCoherence.WARNING:
        return `Le total des prévisions inscrites en recettes de fonctionnement (${recetteFonctionnement}) est supérieur aux dépenses de fonctionnement (${depenseFonctionnement}) : cela n'est autorisé qu'en présence de recettes grévées d'affectation spéciale.`;
      case StatutControleCoherence.OK:
        return `Le total des prévisions budgétaires inscrites en dépenses de fonctionnement est égal aux recettes de fonctionnement (${recetteFonctionnement}).`;
      default:
        return '';
    }
  }

  getLibelle(): string {
    switch (this.statut) {
      case StatutControleCoherence.ERROR:
        return 'Déséquilibre de la section de fonctionnement';
      case StatutControleCoherence.WARNING:
        return 'Suréquilibre de la section de fonctionnement';
      case StatutControleCoherence.OK:
        return 'Équilibre de la section de fonctionnement';
      default:
        return '';
    }
  }

  getStatut(data: EquilibreSectionFonctionnementData): StatutControleCoherence {
    const { depenseFonctionnement, recetteFonctionnement } = data;

    if (depenseFonctionnement === recetteFonctionnement) {
      return StatutControleCoherence.OK;
    }

    if (depenseFonctionnement < recetteFonctionnement) {
      return StatutControleCoherence.WARNING;
    }

    return StatutControleCoherence.ERROR;
  }
}

export default EquilibreSectionFonctionnement;
