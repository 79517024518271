import React from 'react';
import Panel from './Panel';
import BalanceInvestissement from '../../components/simulation/Panels/BalanceInvestissement';

class BalanceInvestissementPanel extends Panel {
  constructor() {
    super();
    this.code = 'BLC_INV';
    this.icon = {
      iconSet: 'Lucide',
      name: 'Building2',
    };
    this.libelle = "Balance d'investissement";
    this.size = 4;
  }

  getComponent = function getComponent(
    data: any,
  ): React.ReactElement {
    return (
      <BalanceInvestissement
        data={data}
      />
    );
  };
}

export default BalanceInvestissementPanel;
