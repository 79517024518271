/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import {
  Button,
  Checkbox,
  Modal,
  type CheckboxProps,
} from '@jvs-group/jvs-mairistem-composants';
import { toast } from 'react-toastify';
import { getErrorMessage } from '@jvs-group/jvs-mairistem-finances-utils';
import TypePeriode from '../../../../constants/simulation/typePeriode';
import type Simulation from '../../../../interfaces/simulation/simulation';
import getPeriodeContentBudgetText from '../../../../utils/simulation/outil';
import type PeriodeModalProps from '../../../../interfaces/simulation/periodeModalProps';
import {
  getMontantSimulationNotSamePropVoteCount,
  propToVote,
  transfertComptabilite,
} from '../../../../services/simulation/simulation';

const renderPeriodeContent = (simulation: Simulation) => {
  switch (simulation?.typePeriode) {
    case TypePeriode.DEMANDE:
      return (
        <>
          {/* eslint-disable-next-line max-len */}
          Pour chaque ligne budgétaire, la proposition de l’ordonnateur affichée va correspondre au total des demandes acceptées.
          <br />
          {/* eslint-disable-next-line max-len */}
          Vous pourrez ensuite inscrire de nouvelles propositions ou ajuster le montant proposé de ces demandes acceptées.
        </>
      );
    case TypePeriode.PROPOSE:
      return (
        <>
          {/* eslint-disable-next-line max-len */}
          Par défaut, sur chaque ligne budgétaire, le montant du vote va être mis à jour avec le montant de la proposition.
          <br />
          {/* eslint-disable-next-line max-len */}
          Vous pourrez modifier le montant voté sur les lignes budgétaires pour lesquelles l'assemblée délibérante n'a pas adopté la proposition budgétaire formulée par l'ordonnateur.
        </>
      );
    case TypePeriode.VOTE: {
      return (
        <>
          {`${getPeriodeContentBudgetText(simulation)} va être rendu exécutoire.`}
          <br />
          Les montants votés alimentent les crédits disponibles pour l'exécution budgétaire.
        </>
      );
    }
    case TypePeriode.CA_CFU:
      return (
        <>Les restes à réaliser vont être validés.</>
      );
    default:
      return '';
  }
};

const NextPeriodeModal = ({
  onClose,
  onValidate,
  open,
  simulation,
  title,
}: PeriodeModalProps) => {
  const [count, setCount] = React.useState<number>(0);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [shouldResetMontant, setShouldResetMontant] = React.useState<boolean>(false);

  const handleFetchMontantCount = async () => {
    if (simulation?.typePeriode === TypePeriode.PROPOSE) {
      try {
        setCount(await getMontantSimulationNotSamePropVoteCount(simulation?.identifiant));
      } catch (e) {
        toast.error(
          getErrorMessage(
            e,
            'Erreur lors de la récupération du nombre de montant ayant une proposition différente du vote',
          ),
        );
      }
    }
  };

  const handleCheckboxChange = (e, { checked }: CheckboxProps) => setShouldResetMontant(checked);

  const handleValidate = async () => {
    if (![TypePeriode.CA_CFU, TypePeriode.PROPOSE, TypePeriode.VOTE].includes(simulation?.typePeriode)) {
      onValidate?.(false);
      return;
    }

    const canTransfertCompta = [TypePeriode.CA_CFU, TypePeriode.VOTE].includes(simulation?.typePeriode);

    try {
      setLoading(true);
      if (simulation?.typePeriode === TypePeriode.PROPOSE) {
        await propToVote(simulation?.identifiant, shouldResetMontant);
      } else if (canTransfertCompta) {
        await transfertComptabilite(simulation?.identifiant);
      }
      onValidate?.(true);
    } catch (e) {
      let errorText = '';
      if (simulation?.typePeriode === TypePeriode.PROPOSE) {
        errorText = 'Erreur lors du vote';
      } else if (canTransfertCompta) {
        errorText = 'Erreur lors du transfert en comptabilité';
      }
      toast.error(getErrorMessage(e, errorText));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      closeIcon
      onClose={onClose}
      onMount={handleFetchMontantCount}
      open={open}
    >
      <Modal.Header content={title} />
      <Modal.Content>
        {renderPeriodeContent(simulation)}
        {(simulation?.typePeriode === TypePeriode.PROPOSE && count > 0) && (
          <>
            <br />
            <br />
            <Checkbox
              checked={shouldResetMontant}
              onChange={handleCheckboxChange}
              label={`Réinitialiser le montant des ${count}
                lignes budgétaires comportant déjà un montant voté différent de la proposition.`}
              toggle
            />
          </>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button
          content="Vers la période suivante"
          data-testid="confirmButton"
          disabled={loading}
          loading={loading}
          onClick={handleValidate}
          positive
        />
      </Modal.Actions>
    </Modal>
  );
};

export default NextPeriodeModal;
