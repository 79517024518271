import React from 'react';
import { Sens } from '@jvs-group/jvs-mairistem-comptabilite';
import OutilTable from '../OutilTable';
import type { OutilTableRowProps } from '../OutilTableRow';
import type Reports from '../../../../interfaces/simulation/reports';

const getReports = (reports: Reports): OutilTableRowProps[] => {
  const titleSens001 = reports?.['001']?.sens === Sens.RECETTE ? 'Excédent' : 'Déficit';
  const titleSens002 = reports?.['002']?.sens === Sens.RECETTE ? 'Excédent' : 'Déficit';
  return ([
    {
      positive: reports?.['001']?.sens === Sens.RECETTE,
      solde: reports?.['001']?.montant,
      title: `${reports?.['001']?.sens} 001 - ${titleSens001} d'investissement reporté`,
    },
    {
      positive: true,
      solde: reports?.['1068']?.montant,
      title: 'R 1068 - Affectation du résultat',
    },
    {
      positive: reports?.['002']?.sens === Sens.RECETTE,
      solde: reports?.['002']?.montant,
      title: `${reports?.['002']?.sens} 002 - ${titleSens002} de fonctionnement reporté`,
    },
  ]);
};

interface ResultatReporteTableProps {
  reports: Reports;
}

const ResultatReporteTable = ({ reports }: ResultatReporteTableProps) => (
  <OutilTable data={getReports(reports)} />
);

export default ResultatReporteTable;
